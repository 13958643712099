import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const QuestionsGoalExecution = lazy(() => lazyRetry(() => import('containers/QuestionsGoalExecution')))
const QuestionsGoalExecutionRegister = lazy(() => lazyRetry(() => import('containers/QuestionsGoalExecution/register')))

const baseUrl = '/questions-goal-execution'

const route = [
  {
    name: i18n.t('routes.questionsGoalExecution.index'),
    path: baseUrl,
    main: QuestionsGoalExecution,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_PERGUNTAS_EXEC_METAS']
  },
  {
    name: i18n.t('routes.questionsGoalExecution.register'),
    path: `${baseUrl}/register`,
    main: QuestionsGoalExecutionRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_PERGUNTAS_EXEC_METAS']
  },
  {
    name: i18n.t('routes.questionsGoalExecution.edit'),
    path: `${baseUrl}/edit/:id`,
    main: QuestionsGoalExecutionRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_PERGUNTAS_EXEC_METAS']
  }
]

export default route
