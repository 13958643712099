import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const TradeAgreementsAscertainment = lazy(() => lazyRetry(() => import('containers/TradeAgreementsAscertainment')))
const EditTradeAgreementsAscertainment = lazy(() => lazyRetry(() => import('containers/TradeAgreementsAscertainment/Edit')))
const Achievements = lazy(() => lazyRetry(() => import('containers/TradeAgreementsAscertainment/Achievements')))
const AscertainmentOn = lazy(() => lazyRetry(() => import('containers/TradeAgreementsAscertainment/AscertainmentOn')))
const LeverExecution = lazy(() => lazyRetry(() => import('containers/LeverExecution')))
const OnePage = lazy(() => lazyRetry(() => import('containers/OnePage')))

const route = [
  {
    name: i18n.t('routes.tradeAgreementsAscertainment.index'),
    path: '/trade-agreements-ascertainment',
    main: TradeAgreementsAscertainment,
    exact: true,
    permissions: [
      'ROLE_APROVAR_APURACOES_ACORDOS_COMERCIAIS',
      'ROLE_EDITAR_APURACAO_ACORDOS',
      'ROLE_ENVIAR_APROVACAO_APURACOES_ACORDOS',
      'ROLE_RESGATAR_APURACOES_ACORDOS',
      'ROLE_VISUALIZAR_EDICAO_APURACAO_ACORDOS'
    ]
  },
  {
    name: i18n.t('routes.tradeAgreementsAscertainment.edit'),
    path: '/trade-agreements-ascertainment/edit/:id',
    main: EditTradeAgreementsAscertainment,
    permissions: [
      'ROLE_APROVAR_APURACOES_ACORDOS_COMERCIAIS',
      'ROLE_EDITAR_APURACAO_ACORDOS',
      'ROLE_ENVIAR_APROVACAO_APURACOES_ACORDOS',
      'ROLE_RESGATAR_APURACOES_ACORDOS',
      'ROLE_VISUALIZAR_EDICAO_APURACAO_ACORDOS'
    ]
  },
  {
    name: i18n.t('routes.tradeAgreementsAscertainment.execution'),
    path: '/trade-agreements-ascertainment/execution',
    main: LeverExecution,
    exact: true,
    permissions: [
      'ROLE_APROVAR_APURACOES_ACORDOS_COMERCIAIS',
      'ROLE_EDITAR_APURACAO_ACORDOS',
      'ROLE_ENVIAR_APROVACAO_APURACOES_ACORDOS',
      'ROLE_RESGATAR_APURACOES_ACORDOS',
      'ROLE_VISUALIZAR_EDICAO_APURACAO_ACORDOS'
    ]
  },
  {
    name: i18n.t('routes.tradeAgreementsAscertainment.achievements'),
    path: '/trade-agreements-ascertainment/achievements-edit',
    main: Achievements,
    exact: true,
    permissions: [
      'ROLE_APROVAR_APURACOES_ACORDOS_COMERCIAIS',
      'ROLE_EDITAR_APURACAO_ACORDOS',
      'ROLE_ENVIAR_APROVACAO_APURACOES_ACORDOS',
      'ROLE_RESGATAR_APURACOES_ACORDOS',
      'ROLE_VISUALIZAR_EDICAO_APURACAO_ACORDOS'
    ]
  },
  {
    name: i18n.t('routes.tradeAgreementsAscertainment.ascertainmentOn'),
    path: '/trade-agreements-ascertainmentOn/ascertainmentOn-edit',
    main: AscertainmentOn,
    exact: true,
    permissions: [
      'ROLE_APROVAR_APURACOES_ACORDOS_COMERCIAIS',
      'ROLE_EDITAR_APURACAO_ACORDOS',
      'ROLE_ENVIAR_APROVACAO_APURACOES_ACORDOS',
      'ROLE_RESGATAR_APURACOES_ACORDOS',
      'ROLE_VISUALIZAR_EDICAO_APURACAO_ACORDOS'
    ]
  },
  {
    name: i18n.t('routes.tradeAgreementsAscertainment.onePage'),
    path: '/trade-agreements-ascertainment/one-page',
    main: OnePage,
    exact: true
  }
]

export default route
