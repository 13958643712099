/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  FormGroup,
  Typography,
  Fab,
  Button,
  IconButton,
  Modal,
  Box,
  TableCell,
  CircularProgress,
  SvgIcon
} from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/Publish'

export const StyledContainer = styled.div`
${props => css`justify-content: ${props.justify || 'flex-start'};`}
display: flex;
flex-direction: column;
margin-left: 70px;
background: #f2f2f2;
min-height: 100vh;
margin-left: 80px;
`

export const StyledPaper = styled.div`
display: flex;
margin: 0px 25px 0px 25px;
align-content: flex-start;
flex-direction: column;
&& h5 {
  color: #2465c3;
  font-weight: bold;
  margin-bottom: 15px;
}
`

export const StyledFormGroup = styled(FormGroup)`
.root {
  flex-direction: row;
  flex-wrap: nowrap;
}
`

export const StyledTitle = styled(Typography)`
&& {
  padding-bottom: 10px;
  flex: 1;
}
`

export const StyledContainerGrid = styled.div`
display: flex;
flex: 1;
flex-direction: column;
justify-content: flex-end;
align-content: flex-end;
`

export const StyledFormContainer = styled.div`
position: relative;
${({ grid }) => css`padding: 0 ${grid ? 0 : 20}px 0 0;`}
`

export const StyledFormTitle = styled(Typography)`
color: #939393;
${({ grid }) => css`margin: ${grid ? 0 : 10}px 0;`}
`

export const StyledFab = styled(({ nomargin, ...other }) => (
  <Fab
    classes={{
      sizeSmall: 'small',
      colorInherit: 'root'
    }}
    {...other}
  />
))`
${({ nomargin }) => css`margin: ${nomargin === 1 ? 0 : 18}px;`}

&.root {
  background: #fff;
  width: 70px;
  height: 70px;
}
& .small {
  width: 2px;
  height: 2px;
}
& .large {
  background: #fff;
}
`

export const StyledGridTitle = styled(Typography)`
&& {
  color: #2465c3;
}
`
export const StyledButton = styled(forwardRef(({ nomargintop, nomarginright, custommargin, customcolor, isFilter, iconOnRight, ...props }, ref) => (<Button {...props} ref={ref} />)))`
&& {
  margin: 20px 18px 0 0;
  cursor: pointer;
  font-size: 0.8rem;
  p {
    font-size: 0.8rem;
  }
  ${({ nomarginright }) => nomarginright && css`margin-right: 0;`}
  ${({ nomargintop }) => nomargintop && css`margin-top: 0;`}
  ${({ custommargin }) => custommargin && css`margin: ${custommargin};`}
  ${({ customcolor }) => customcolor && css`background-color: ${customcolor};`}
  ${({ isFilter }) => (isFilter ?
    css`
    color: #5f5f5f;
    border: none;
    text-transform: none;
    background-color: transparent;
    span svg {
      margin-left: 0.2rem;
    }
    :hover{
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.08);
    }` :
    css`
    height: 36px;
    min-width: 120px;
    font-weight: normal;
    text-transform: none;
  `)}
}

& span svg.MuiSvgIcon-root {
  ${({ nomarginleft, iconOnRight }) => !iconOnRight && css`
    margin-left: ${nomarginleft === 1 ? '0' : '0.2rem'};
  `}
  ${({ iconOnRight }) => iconOnRight && css`margin-right: 0.2rem;`}
}

&&.Mui-disabled {
  cursor: not-allowed;
  opacity: 0.8;
  pointer-events: auto;
}

&.MuiButton-colorInherit {
  color: #e10050;
}

&&.Mui-disabled.MuiButton-colorInherit {
  border-color: #e10050;
  background-color: rgba(0, 0, 0, 0.12);
}
`

export const StyledModal = styled(forwardRef(({ customposition, maxwidth, margintop, isCentered, fileStyle, ...props }, ref) => (<Modal {...props} ref={ref} />)))`
&&{
  overflow: auto;
  ${({ isCentered }) => css`margin: ${isCentered ? 'auto' : ''};`}
  ${({ customposition }) => css`position: ${customposition} !important;`}
  ${({ maxwidth }) => maxwidth && css`max-width: ${maxwidth};`}
  ${({ margintop }) => margintop && css` margin-top: ${margintop};`}
}
& > div[role='document']:focus {
  outline: unset;
}
& .MuiPaper-root-10 {
  ${({ fileStyle }) => fileStyle}
}
`

export const StyledFile = styled(({ ...others }) => (
  <IconButton {...others}>
    <FileCopyIcon />
  </IconButton>
))``

export const StyledHeader = styled.div`
${({ minheight }) => css`min-height: ${minheight || 100}px;`}
background-color: #fff;
border-bottom: 1px solid #dadada;
position: relative;
`

export const StyledButtonIcon = styled(forwardRef(({ nomargintop, nomargin, customColor, customColorHover, ...props }, ref) => (<Button {...props} ref={ref} />)))`
p {
  margin: 0px 0.5rem;
  font-size: 0.8rem;
}
.Mui-disabled {
  cursor: not-allowed;
  pointer-events: auto;
}
float: right;
margin-right: 54px;
${({ nomargintop, nomargin }) => css`margin-top: ${nomargintop || nomargin ? '0' : '30px'};`}
${({ nomargin }) => nomargin && css`margin: 0`}
${({ customColor }) => customColor && css`background-color: ${customColor}`}
:hover{
  ${({ customColorHover }) => customColorHover && css`background-color: ${customColorHover}`}
}
& span svg {
  margin-left: 0.2rem;
}

&&.Mui-disabled {
  cursor: not-allowed;
  opacity: 0.8;
  pointer-events: auto;
}

&.MuiButton-colorInherit {
  color: #e10050;
}

&&.Mui-disabled.MuiButton-colorInherit {
  border-color: #e10050;
  background-color: rgba(0, 0, 0, 0.12);
}
`

export const StyledBtnFilter = styled(forwardRef(({ marginleft, ...props }, ref) => (<Button {...props} ref={ref} />)))`
color: #5f5f5f;
border: none;
text-transform: none;
margin-top: 12px;
background-color: transparent;
font-size: 0.8rem;
${({ marginleft }) => css`margin-left:  ${marginleft || '25px'};`}
  & span svg {
  margin-left: 0.2rem;
}
`

export const StyledTitlePage = styled(Typography)`
${({ color }) => color === undefined && css` color:  #011738 ;`}
font-size: 18px;
`
export const StyledSubTitlePage = styled(Typography)`
${({ color }) => color === undefined && css` color:  #8492a6 ;`}
font-size: 14px;
margin-top: 5px;
`
export const ExitFilter = styled(Box)`
position: absolute;
right: 50px;
cursor: pointer;
z-index: 100;
& svg {
  margin-right: 5px;
  vertical-align: middle;
  display: inline-block;
}

& span {
  vertical-align: middle;
  display: inline-block;
}
`
export const StyledTableCell = styled(TableCell)`
border-width: 0;
justify-items: center;
align-items: center;
button {
  margin-right: 1rem;
}
`

export const BoxButtonsFilter = styled(Box)`
float: right;
padding-right: 10px;
margin-top: 10px;
`
export const StyledCircularProgress = styled(CircularProgress)`
color: #fff;
position: absolute;
top: '50%';
left: '50%';
`

export const StyledBtn = styled(forwardRef(({ customcolor, priceMasterAction, ...props }, ref) => (<Button {...props} ref={ref} />)))`
${({ customcolor }) => css`background-color: ${customcolor || '#206bc4'};`}
height: 26px;
border-radius: 5px;
text-transform: none;
font-size: 0.8rem;
&& .MuiSvgIcon-root{
  margin-right: 0.2rem;
}
${({ priceMasterAction }) => priceMasterAction && css`
  color: #FFFFFF;
  :hover {
    color: #333333;
  }
`}
`

StyledBtn.propTypes = {
  priceMasterAction: PropTypes.bool
}

StyledBtn.defaultProps = {
  priceMasterAction: false
}

export const StyledButtonAction = styled(forwardRef(({ custombgcolor, customcolor, ...props }, ref) => (<Button {...props} ref={ref} />)))`
&&{
  border-radius: 0.2rem;
  text-transform: none;
  padding: 0.2rem 0.4rem;
  min-width: 6rem;
  font-size: 0.8rem;
  ${({ custombgcolor }) => css`background-color: ${custombgcolor || '#206bc4'};`}
  ${({ customcolor }) => css`color: ${customcolor || '#FFFFFF'};`}

  :hover {
    ${({ custombgcolor }) => css`background-color: ${custombgcolor || '#206bc4'};`}
    opacity: 0.6;
  }
  & svg {
    margin-left: 0.4rem;
  }
  &&.Mui-disabled {
    opacity: 0.6;
    background-color: #466f9e;
    color: #ffffff;
  }
}
`
export const StyledCabecalhoLeftSide = styled.div`
display: flex;
flex-wrap: wrap;
background: #f7f7f7;
border: 1px solid #e5e9f2;
border-radius: 4px;
height: 100%;
`

export const StyledButtonCabecalho = styled(forwardRef(({ customcolor, justify, customiconcolor, rotate, ...props }, ref) => (<Button {...props} ref={ref} />)))`
align-items: center;
${({ customcolor }) => css`background-color: ${customcolor};`}
${({ justify }) => justify.length > 0 && css`justify-content: ${justify};`}
p {
  margin: 0px 0.5rem;
  font-size: 0.8rem;
}
.Mui-disabled {
  cursor: not-allowed;
  pointer-events: auto;
}
.MuiSvgIcon-root {
  ${({ customiconcolor }) => customiconcolor.length > 0 && css`color: ${customiconcolor};`}
  ${({ rotate }) => css`transform: rotate(${rotate});`}
}
`
StyledButtonCabecalho.propTypes = {
  justify: PropTypes.string,
  rotate: PropTypes.string,
  customiconcolor: PropTypes.string
}

StyledButtonCabecalho.defaultProps = {
  justify: 'center',
  rotate: '0deg',
  customiconcolor: ''
}

export const StyledContainerTitles = styled(({ noFloat, ...props }) => <div {...props} />)`
min-width: 250px;
margin-top: 25px;
margin-left: 25px;
${({ noFloat }) => !noFloat && css`float: left;`}
`

StyledContainerTitles.defaultProps = {
  noFloat: false
}

export const StyledParagraph = styled(Typography)`
margin-top: 1rem;
`

export const StyledEmphasis = styled.span`
font-weight: bold;
font-style: italic;
`

export const StyledSvgIcon = styled(SvgIcon)`
  padding: 2px;
`
