import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const AscertainmentACTPM = lazy(() => lazyRetry(() => import('containers/AscertainmentACTPM')))
const AscertainmentACTPMReport = lazy(() => import('containers/AscertainmentACTPMReport'))

const route = [
  {
    name: i18n.t('ascertainmentACTPM.title'),
    path: '/ascertainment-actpm',
    main: AscertainmentACTPM,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_ACTPM']
  },
  {
    name: i18n.t('ascertainmentACTPM.reportTitle'),
    path: '/ascertainment-actpm/report',
    main: AscertainmentACTPMReport,
    exact: true,
    permission: ['ROLE_VISUALIZAR_ACTPM']
  }
]

export default route