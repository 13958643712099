import React, { useState, useEffect, useCallback, useMemo, memo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import toast from 'common/utils/toast'
import { useTranslation } from 'react-i18next'
import Calendar from '../Calendar'
import Grid from '@material-ui/core/Grid'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {
  StyledAppBar,
  StyledDrawer,
  StyledUserName,
  StyledAccountCircleIcon,
  StyledTradeMarkContainer,
  StyledImageContainer,
  StyledToolbar,
  StyledTradeMarkBox
} from './styles'
import { useStore } from 'components/Store'
import ItemsList from './components/ItemsList'
import { getRoutes } from './help/routes'
import { filterRoutesByPermissions } from 'common/utils/menu'
import { routesPermissions } from 'routes'
import { ReactComponent as LogoGPC } from 'assets/images/logo-gpc-white.svg'
import UnileverTrademark from 'assets/images/img-logounileverbranca.png'
import ChangePasswordModal from './components/modals/ChangePasswordModal'
import RequestUserEditionModal from './components/modals/RequestUserEditionModal'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'

function MiniDrawer () {
  const [menuItems, setMenuItems] = useState([])
  const [clickedOnLogout, setClickedOnLogout] = useState(false)
  const [menuState, setMenuState] = useState({
    opened: false,
    blocked: false
  })
  const [modalChangePassword, setModalChangePassword] = useState({
    openModal: false,
    data: {}
  })
  const [modalRequestUserEdition, setModalRequestUserEdition] = useState({
    openModal: false,
    data: {}
  })

  const location = useLocation()
  const history = useHistory()

  const { store, setStore } = useStore('AUTH')

  const { t } = useTranslation()

  const disableMenu = location.state && location.state.fullWidth

  const openModalChangePassword = useCallback(() => {
    setModalChangePassword(prevState => ({
      ...prevState,
      openModal: true
    }))
  }, [])

  const openRequestUserEditionModal = useCallback(() => {
    setModalRequestUserEdition(prevState => ({
      ...prevState,
      openModal: true
    }))
  }, [])

  const closeMenu = useCallback(() => {
    setMenuState({ blocked: false, opened: false })
  }, [])

  const handleToggleMenu = useCallback((open) => {
    if (!menuState.blocked) {
      setMenuState(currentState => ({ ...currentState, opened: open }))
    }
  }, [menuState.blocked])

  const mouseOver = () => {
    if (!menuState.opened) {
      handleToggleMenu(true)
    }
  }

  const clickAway = () => {
    if (!_isEqual(menuState, { blocked: false, opened: false })) {
      setMenuState({ blocked: false, opened: false })
    }
  }

  const sessionExpiredRedirect = useCallback(() => {
    if (location.pathname !== '/login' && !clickedOnLogout) {
      toast.warn(t('general.expiredToken'))
      history.push('/login')
    }
  }, [clickedOnLogout, history, location, t])

  const logout = useCallback(() => {
    window.localStorage.clear()
    window.sessionStorage.clear()
    window.localStorage.setItem('expiredToken', true)
    setClickedOnLogout(true)
    setStore({ access_token: '', isLogged: false }, () => {
      history.push('/login')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  useEffect(() => {
    const interval = setInterval(() => {
      const accessToken = window.localStorage.getItem('access_token')
      const expiredToken = window.localStorage.getItem('expiredToken')
      if (!accessToken || (expiredToken === 'true' && location.pathname !== '/login')) {
        clearInterval(interval)
        sessionExpiredRedirect()
        setClickedOnLogout(false)
      }
    }, 4000)
    return () => clearInterval(interval)
  }, [sessionExpiredRedirect, history, location, t])

  useEffect(() => {
    const routes = getRoutes(closeMenu, logout, openModalChangePassword, openRequestUserEditionModal)
    setMenuItems(filterRoutesByPermissions(routes, routesPermissions, store.access_token))
  }, [closeMenu, history, logout, openModalChangePassword, openRequestUserEditionModal, store.access_token])

  const findMenuOption = useCallback((list, prop, value) => list.find((currentElement) => {
    const { subItems = [] } = currentElement
    if (currentElement[prop] === value) {
      return true
    }
    if (subItems.length) {
      return findMenuOption(subItems, prop, value)
    }
    return false
  }), [])

  const activeMenuItem = useMemo(() => _get(findMenuOption(menuItems, 'path', location.pathname), 'menuItem', ''), [findMenuOption, location.pathname, menuItems])

  if (window.localStorage.getItem('expiredToken') === 'true') {
    sessionExpiredRedirect()
    return null
  }

  return (
    <>
      <StyledAppBar position="fixed">
        <StyledToolbar>
          <Grid container justify="flex-end" alignItems="center">
            <StyledTradeMarkContainer>
              <Grid container justify="flex-start" alignItems="center">
                <StyledTradeMarkBox item onClick={() => history.push('/')} title={t('menu.homepage')}>
                  <Grid container>
                    <Grid item>
                      <LogoGPC />
                    </Grid>
                    <Grid item>
                      <StyledImageContainer>
                        <img src={UnileverTrademark} alt={t('menu.unileverTrademark')} />
                      </StyledImageContainer>
                    </Grid>
                  </Grid>
                </StyledTradeMarkBox>
              </Grid>
            </StyledTradeMarkContainer>
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <StyledUserName>{store.name}</StyledUserName>
                </Grid>
                <Grid item>
                  <StyledAccountCircleIcon />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledToolbar>
      </StyledAppBar>
      {!disableMenu && (
        <ClickAwayListener onClickAway={clickAway}>
          <StyledDrawer
            variant="permanent"
            ancor="top"
            open={menuState.opened}
            // onClick={() => setMenuState(menuState => ({ ...menuState, blocked: !menuState.blocked }))}
            onMouseOver={mouseOver}
            onMouseLeave={() => handleToggleMenu(false)}
          >
            <ItemsList
              activeMenuItem={activeMenuItem}
              menuState={menuState}
              setMenuState={setMenuState}
              items={menuItems}
            />
          </StyledDrawer>
        </ClickAwayListener>
      )}
      <Calendar />
      <ChangePasswordModal
        modalState={modalChangePassword}
        setModalState={setModalChangePassword}
        logout={logout}
      />
      <RequestUserEditionModal
        modalState={modalRequestUserEdition}
        setModalState={setModalRequestUserEdition}
        logout={logout}
        userData={store}
      />
    </>
  )
}

export default memo(MiniDrawer)
