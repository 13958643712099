import React, { useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import MultiSelect from 'components/MultiSelect'
import { StyledForm, StyledMainFilters, StyledSelect } from './styled'

export const initialValues = {
  eventTypes: null
}

function Filters({ filterData, values, handleChange }) {
  const { t } = useTranslation()

  useEffect(() => {
    handleChange(values)
  }, [handleChange, values])
  return (
    <StyledForm>
      <StyledMainFilters>
        <StyledSelect>
          <MultiSelect
            name="eventTypes"
            isClearable
            label={t('general.eventTypes')}
            fullWidth
            options={filterData}
            variant="outlined"
            isMulti={false}
          />
        </StyledSelect>
      </StyledMainFilters>
    </StyledForm>
  )
}

Filters.defaultProps = {
  filterData: [],
  setValues: () => {},
  values: {},
  handleChange: () => {}
}

Filters.propTypes = {
  filterData: PropTypes.array,
  setValues: PropTypes.func,
  values: PropTypes.object,
  handleChange: () => {}
}

export default memo(Filters)
