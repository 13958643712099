import React from 'react'
import { StyledContainer, GlobalStyle } from './styled'
import toast from 'common/utils/toast'

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props)
      this.state = { hasError: false, error: '', errorInfo: '', fullError: '' }
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true }
    }
  
    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo)
        this.setState({
            error: error,
            errorInfo: errorInfo,
            fullError: `Error: ${error.stack}, ErrorInfo: ${errorInfo.componentStack}`
        })
    }

    handleCopy = () => {
        navigator.clipboard.writeText(this.state.fullError)
        toast.success('Copiado com sucesso!')
    }
  
    render() {
      if (this.state.hasError) {
        return (
            <>
                <GlobalStyle />
                <StyledContainer>
                    {document.head.insertAdjacentHTML(
                        'beforeend',
                        '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />'
                    )}
                    <div className="card">
                        <div className="card-header">
                            <i class="fas fa-exclamation-triangle"></i>
                            <h1 className="title"> Ocorreu um erro inesperado </h1>
                            <h2 className="sub-title"> Contate a equipe de atendimento, informando o erro abaixo: </h2>
                        </div>
                        <div className="card-body">
                            <div className="actions-box">
                                <button 
                                    className="basic-button"
                                    onClick={() => this.handleCopy()}
                                >
                                    Copiar
                                </button>
                                <a
                                    className="basic-button"
                                    href={URL.createObjectURL(new Blob([this.state.fullError], {type: 'text/plain'}))}
                                    download="errorEvidence.txt"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Download
                                </a>
                            </div>
                            <p className="text"> {this.state.error.stack} </p>
                            <p className="text"> {this.state.errorInfo.componentStack} </p>
                        </div>
                    </div>
                </StyledContainer>
            </>
        )
      }
  
      return this.props.children; 
    }
}

export default ErrorBoundary