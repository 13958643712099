import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Counting = lazy(() => lazyRetry(() => import('containers/Counting')))
const DistributeMoney = lazy(() => lazyRetry(() => import('containers/Counting/DistributeMoney')))
const AssignActions = lazy(() => lazyRetry(() => import('containers/Counting/AssignActions')))

const route = [
  {
    name: i18n.t('routes.counting.index'),
    path: '/counting',
    main: Counting,
    exact: true,
    permissions: [
      'ROLE_APROVAR_APURACAO_PASTA_CICLO',
      'ROLE_EDITAR_APURACAO_PASTA_CICLO',
      'ROLE_ENVIAR_APROVACAO_PASTA_CICLO',
      'ROLE_VISUALIZAR_APURACAO_PASTA_CICLO',
      'ROLE_EDITAR_DISTRIBUICAO_COORDENADOR'
    ]
  },
  {
    name: i18n.t('routes.counting.distributeMoney'),
    path: '/counting/distribute-money',
    main: DistributeMoney,
    exact: true,
    permissions: ['ROLE_EDITAR_APURACAO_PASTA_CICLO', 'ROLE_EDITAR_DISTRIBUICAO_COORDENADOR']
  },
  {
    name: i18n.t('routes.counting.assignActions'),
    path: '/counting/assignActions',
    main: AssignActions,
    exact: true,
    permissions: ['ROLE_EDITAR_APURACAO_PASTA_CICLO']
  }
]

export default route
