import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const TargetExecutionProduct = lazy(() => lazyRetry(() => import('containers/TargetExecutionProduct')))
const TargetExecutionProductRegister = lazy(() => lazyRetry(() => import('containers/TargetExecutionProduct/register')))

const route = [
  {
    name: i18n.t('routes.targetExecutionProduct.index'),
    path: '/target-execution-product',
    main: TargetExecutionProduct,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_PRODUTO_EXEC_METAS', 'ROLE_EDITAR_CADASTRO_PROD_EXEC_METAS']
  },
  {
    name: i18n.t('routes.targetExecutionProduct.register'),
    path: '/target-execution-product/register',
    main: TargetExecutionProductRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_PROD_EXEC_METAS']
  },
  {
    name: i18n.t('routes.targetExecutionProduct.edit'),
    path: '/target-execution-product/edit/:id',
    main: TargetExecutionProductRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_PROD_EXEC_METAS']
  }
]

export default route
