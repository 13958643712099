import styled, { css } from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'

const StyledCloseButton = styled.div`
  cursor: pointer;
  margin-left: 6px;
  display: flex;
  align-items: center;
`

const StyledChipBody = styled.div`
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  color: #555a5f;
  border-radius: 4px;
  background: #eae8fd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 4px;
  margin: ${props => `${props.verticalMargin}px ${props.horizontalMargin}px`}
  ${({ size }) => (size !== 'big' ? css`padding-right: 4px;` : '')}
  div, div:hover {
    background: transparent !important;
    margin: 0px !important;
    svg {
      fill: #555A5F
    }
  }
`

const StyledChipText = styled.span`
  max-width: 216px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ size }) => (size === 'big' ? css`font-size: 16px;` : css`font-size: 12px;`)}
`

const StyledTooltip = styled(Tooltip)``

export {
  StyledChipBody,
  StyledCloseButton,
  StyledChipText,
  StyledTooltip
}
