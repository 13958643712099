import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Chat = lazy(() => lazyRetry(() => import('containers/Chat')))
const ChatRegister = lazy(() => lazyRetry(() => import('containers/Chat/register')))

const baseUrl = '/chat'

const route = [
  {
    name: i18n.t('routes.chat.index'),
    path: baseUrl,
    main: Chat,
    exact: true
  },
  {
    name: i18n.t('routes.chat.register'),
    path: `${baseUrl}/register`,
    main: ChatRegister,
    exact: true
  },
  {
    name: i18n.t('routes.chat.edit'),
    path: `${baseUrl}/edit/:id`,
    main: ChatRegister,
    exact: true
  }
]

export default route
