import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import {
  GlobalStyle,
  StyledWrapper,
  StyledCard,
  StyledRadioButtons,
  StyledActionButtons,
  StyledTitle,
  StyledHeader,
  StyledSubTitle,
  StyledTextArea,
  StyledTextAreaLabel
} from './styled'
import { Formik } from 'formik'
import * as yup from 'yup'
import i18n from 'common/i18n'
import RadioGroup from 'components/Form/RadioGroup'
import Button from 'components/Button'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TimelineIcon from '@material-ui/icons/Timeline'
import Input from 'components/Form/Input'
import { useStore } from 'components/Store'
import { saveNPS } from './help/requests'
import { messageServiceError } from 'help/message'
import toast from 'common/utils/toast'

const initialValues = {
  rating: null,
  comment: ''
}

const validationSchema = yup.object().shape({
  rating: yup.number().nullable().required(i18n.t('general.fieldRequired'))
})

function Nps() {
  const { t } = useTranslation()

  const location = useLocation()
  const history = useHistory()

  const { store } = useStore('AUTH')

  location.state = {
    fullWidth: true,
    disableMenu: true,
    disableHeader: true
  }

  const sendNps = useCallback((values) => {
    saveNPS({
      nota: Number(values.rating, 10),
      comentario: values.comment.length ? values.comment : null
    }).then(() => {
      toast.success(t('nps.successMessageSaveNPS'))
      history.push('/')
    }).catch(messageServiceError)
  }, [history, t])

  const cancelAction = useCallback(() => {
    history.push('/')
  }, [history])

  useEffect(() => {
    if (store.answeredNps) {
      cancelAction()
    }
  }, [cancelAction, store])

  return (
    <>
      <GlobalStyle />
      <StyledWrapper>
        <StyledCard>
          <StyledHeader>
            <StyledSubTitle> {t('nps.subTitle')} </StyledSubTitle>
            <StyledTitle> {t('nps.title')} </StyledTitle>
            <TimelineIcon />
          </StyledHeader>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={sendNps}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <RadioGroup required name="rating" label={t('nps.rating')}>
                  <StyledRadioButtons>
                    {
                      [...Array(11).keys()].map(number => (
                        <FormControlLabel
                          key={number.toString()}
                          value={number.toString()}
                          control={<Radio color="primary" />}
                          checked={values.rating === number.toString()}
                          onChange={e => setFieldValue('rating', e.target.value)}
                          label={number.toString()}
                        />
                      ))
                    }
                  </StyledRadioButtons>
                </RadioGroup>
                <StyledTextAreaLabel className="MuiFormLabel-root"> {t('nps.comment')} </StyledTextAreaLabel>
                <StyledTextArea>
                  <Input
                    name='comment'
                    label={t('nps.commentPlaceHolder')}
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={5}
                    inputProps={{ maxLength: 10000 }}
                  />
                </StyledTextArea>
                <StyledActionButtons>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {t('general.actions.send')}
                  </Button>
                  <Button
                    isText
                    underlined
                    variant="contained"
                    fontColor="#212529"
                    onClick={cancelAction}
                  >
                    {t('general.actions.cancel')}
                  </Button>
                </StyledActionButtons>
              </form>
            )}
          </Formik>
        </StyledCard>
      </StyledWrapper>
    </>
  )
}

export default Nps
