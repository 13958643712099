import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const CycleFolder = lazy(() => lazyRetry(() => import('containers/CycleFolder')))
const ReportCycleFolderCountingDetail = lazy(() => lazyRetry(() => import('containers/Counting/ReportCycleFolderCountingDetail')))
const RegionalCycleFolderReport = lazy(() => lazyRetry(() => import('containers/RegionalCycleFolderReport')))
const CycleFolderPaymentsReport = lazy(() => lazyRetry(() => import('containers/CycleFolderPaymentsReport')))
const CycleFolderMaintenanceDetailedReport = lazy(() => lazyRetry(() => import('containers/CycleFolderMaintenanceDetailedReport')))
const CycleFolderGeneralReport = lazy(() => lazyRetry(() => import('containers/CycleFolderGeneralReport')))
const SummariesTacticalfundsOff = lazy(() => lazyRetry(() => import('containers/SummariesTacticalfundsOff')))
const SummariestacticalfundsON = lazy(() => lazyRetry(() => import('containers/SummariestacticalfundsON')))

const route = [
  {
    name: i18n.t('routes.cycleFolder.index'),
    path: '/cycleFolder',
    main: CycleFolder,
    exact: true,
    permissions: [
      'ROLE_ABRIR_PASTA_CICLO',
      'ROLE_FECHAR_PASTA_CICLO',
      'ROLE_APROVAR_PASTA_CICLO',
      'ROLE_EDITAR_PASTA_CICLO',
      'ROLE_VISUALIZAR_PASTA_CICLO',
      'ROLE_EDITAR_VALORIZACAO_PASTA',
      'ROLE_VISUALIZAR_VALORIZACAO_PASTA'
    ]
  },
  {
    name: i18n.t('menu.cycleFolder.cycleFolderAscertainmentDetails'),
    path: '/report-cycle-folder-counting-detail',
    main: ReportCycleFolderCountingDetail,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_RELATORIO_APURACAO_PASTA']
  },
  {
    name: i18n.t('routes.summaryFundsOff.index'),
    path: '/cycle-folder/reports/resume-funds-tactics-off',
    main: SummariesTacticalfundsOff,
    exact: true
  },
  {
    name: i18n.t('menu.cycleFolder.reports.regionalCycleFolder'),
    path: '/cycle-folder/reports/regional-cycle-folder',
    main: RegionalCycleFolderReport,
    exact: true,
    permissions: ['ROLE_RELATORIO_PASTA_CICLO_REGIONAL']
  },
  {
    name: i18n.t('routes.cycleFolder.generalReport'),
    path: '/cycle-folder/reports/general',
    main: CycleFolderGeneralReport,
    permissions: ['ROLE_RELATORIO_PASTA_CICLO']
  },
  {
    name: i18n.t('routes.cycleFolder.maintenanceDetailedReport'),
    path: '/cycle-folder/reports/detailed-maintenance',
    main: CycleFolderMaintenanceDetailedReport,
    exact: true,
    permissions: ['ROLE_RELATORIO_MANUTENCOES_PASTA_CICLO_DETALHADO']
  },
  {
    name: i18n.t('routes.cycleFolder.paymentsReport'),
    path: '/cycle-folder/reports/payments',
    main: CycleFolderPaymentsReport,
    exact: true,
    permissions: ['ROLE_RELATORIO_PAGAMENTOS_PASTA_CICLO']
  },
  {
    name: i18n.t('routes.summaryFundsON.index'),
    path: '/cycle-folder/reports/resume-funds-tactics-on',
    main: SummariestacticalfundsON,
    exact: true
  }
]

export default route
