import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const CustomerAndSap = lazy(() => lazyRetry(() => import('containers/CustomerAndSap')))
const CustomerAndSapRegister = lazy(() => lazyRetry(() => import('containers/CustomerAndSap/register')))

const route = [
  {
    name: i18n.t('routes.customerAndSap.index'),
    path: '/customer',
    main: CustomerAndSap,
    exact: true,
    permissions: ['ROLE_MARCAR_CLIENTE_SAP_PAGADOR', 'ROLE_VISUALIZAR_CADASTRO_CLIENTE', 'ROLE_EDITAR_CADASTRO_CLIENTE']
  },
  {
    name: i18n.t('routes.customerAndSap.register'),
    path: '/customer/register',
    main: CustomerAndSapRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_CLIENTE']
  },
  {
    name: i18n.t('routes.customerAndSap.edit'),
    path: '/customer/edit/:id',
    main: CustomerAndSapRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_CLIENTE', 'ROLE_MARCAR_CLIENTE_SAP_PAGADOR']
  }
]

export default route
