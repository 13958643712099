import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Agreements = lazy(() => lazyRetry(() => import('containers/Agreements')))
const AgreementsForm = lazy(() => lazyRetry(() => import('containers/Agreements/AgreementsRegister')))
const EditContract = lazy(() => lazyRetry(() => import('containers/Agreements/AgreementsEdit')))
const AgreementsOn = lazy(() => lazyRetry(() => import('containers/Agreements/AgreementsOn')))

const route = [
  {
    name: i18n.t('routes.agreements.index'),
    path: '/agreements',
    main: Agreements,
    exact: true,
    permissions: [
      'ROLE_APROVAR_ACORDO_COMERCIAL',
      'ROLE_CANCELAR_ACORDO_COMERCIAL',
      'ROLE_EDITAR_ACORDO_COMERCIAL',
      'ROLE_ENCERRAR_ACORDO_RETROATIVO',
      'ROLE_ENVIAR_APROVACAO_ACORDOS_COMERCIAIS',
      'ROLE_MANUTENCAO_ASSINATURA_ACORDOS',
      'ROLE_PRORROGAR_ACORDO_COMERCIAL',
      'ROLE_RELATORIO_CONSOLIDADO_ACORDOS',
      'ROLE_VISUALIZAR_ACORDOS_COMERCIAIS'
    ]
  },
  {
    name: i18n.t('routes.agreements.register'),
    path: '/agreements/register',
    main: AgreementsForm,
    exact: true,
    permissions: ['ROLE_EDITAR_ACORDO_COMERCIAL']
  },
  {
    name: i18n.t('routes.agreements.edit'),
    path: '/agreements/edit/:id',
    main: EditContract,
    exact: true,
    permissions: ['ROLE_EDITAR_ACORDO_COMERCIAL', 'ROLE_VISUALIZAR_EDICAO_ACORDOS_COMERCIAIS']
  },
  {
    name: i18n.t('routes.agreements.agreementsOn'),
    path: '/agreements/agreementsOn-edit',
    main: AgreementsOn,
    exact: true,
    permissions: ['ROLE_EDITAR_ACORDO_COMERCIAL', 'ROLE_VISUALIZAR_EDICAO_ACORDOS_COMERCIAIS']
  }
]

export default route
