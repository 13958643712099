export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const clearLoginData = () => {
  window.localStorage.removeItem('expiredToken')
  window.localStorage.removeItem('access_token')
  // window.localStorage.removeItem('ngStorage-token')
}

export const removeAccents = (str) => {
  const strAccents = str.split('')
  let strAccentsOut = []
  const strAccentsLen = strAccents.length
  const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
  const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'
  for (let y = 0; y < strAccentsLen; y += 1) {
    if (Number(accents.indexOf(strAccents[y])) !== -1) {
      strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1)
    } else { strAccentsOut[y] = strAccents[y] }
  }
  strAccentsOut = strAccentsOut.join('')
  return strAccentsOut
}

export const lazyRetry = function(componentImport) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(storageKey) || 'false')
    componentImport()
      // eslint-disable-next-line consistent-return
      .then((component) => {
        window.sessionStorage.setItem(storageKey, 'false')
        if (component === undefined) {
          window.sessionStorage.setItem(storageKey, 'true')
          return window.location.reload(true)
        }
        resolve(component)
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(storageKey, 'true')
          window.location.reload(true)
        }
        reject(error)
      })
  })
}
