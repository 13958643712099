import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const CustomerPdv = lazy(() => lazyRetry(() => import('containers/CustomerPdv')))
const CustomerPdvRegister = lazy(() => lazyRetry(() => import('containers/CustomerPdv/register')))

const route = [
  {
    name: i18n.t('routes.customerPdv.index'),
    path: '/customer-pdv',
    main: CustomerPdv,
    exact: true,
    permissions: [
      'ROLE_EDITAR_CADASTRO_CLIENTE_PDV',
      'ROLE_VISUALIZAR_CADASTRO_CLIENTE_PDV',
      'ROLE_CADASTRAR_CLIENTE_PDV'
    ]
  },
  {
    name: i18n.t('routes.customerPdv.register'),
    path: '/customer-pdv/register',
    main: CustomerPdvRegister,
    exact: true,
    permissions: [ 'ROLE_CADASTRAR_CLIENTE_PDV', 'ROLE_EDITAR_CADASTRO_CLIENTE_PDV' ]
  },
  {
    name: i18n.t('routes.customerPdv.edit'),
    path: '/customer-pdv/edit/:id',
    main: CustomerPdvRegister,
    exact: true,
    permissions: [ 'ROLE_EDITAR_CADASTRO_CLIENTE_PDV', 'ROLE_CADASTRAR_CLIENTE_PDV' ]
  }
]

export default route
