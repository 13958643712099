import Api from './api/apiGPC'
import { buildRequestParamsObj } from 'help/requests'

const baseUrl = '/calendario'

const calendar = {
  getListEvents(data) {
    return Api.request(`${baseUrl}/buscarNomeEventos`,
      buildRequestParamsObj(data))
  },
  fetchListingData(data) {
    return Api.request(`${baseUrl}/pesquisar`,
      buildRequestParamsObj(data))
  },
  saveEvent(data) {
    return Api.request(`${baseUrl}/salvar`,
      buildRequestParamsObj(data))
  },
  removeEvent(data) {
    return Api.request(`${baseUrl}/excluir`,
      buildRequestParamsObj(data))
  }
}

export default calendar
