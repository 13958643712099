import React from 'react'
import styled, { css } from 'styled-components'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

export const StyledSubList = styled(List)`
  &&{
    padding-left: 56px;
  }
`

export const StyledListItemText = styled(({ rootLevel, isSubitem, ...props }) => <ListItemText {...props} />)`
  && {
    ${({ rootLevel }) => rootLevel && css`white-space: nowrap;`};
    width: fit-content;
  }
  .MuiTypography-root{
    ${({ isSubitem }) => !isSubitem && css`white-space: nowrap;`}
  }
`

export const StyledListItem = styled(({ selected, ...props }) => <ListItem {...props} />)`
  ${({ selected }) => selected && css`
    border-left: 3px solid #032587;
    background-color: rgba(0, 0, 0, 0.05);
  `}
`
