import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const ProductsGroup = lazy(() => lazyRetry(() => import('containers/ProductsGroup')))
const ProductsGroupRegister = lazy(() => lazyRetry(() => import('containers/ProductsGroup/register')))

const route = [
  {
    name: i18n.t('routes.productsGroup.index'),
    path: '/products-group',
    main: ProductsGroup,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_GRUPO_PRODUTO', 'ROLE_EDITAR_CADASTRO_GRUPO_PRODUTO']
  },
  {
    name: i18n.t('routes.productsGroup.register'),
    path: '/products-group/register',
    main: ProductsGroupRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_GRUPO_PRODUTO']
  },
  {
    name: i18n.t('routes.productsGroup.edit'),
    path: '/products-group/edit/:id',
    main: ProductsGroupRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_GRUPO_PRODUTO']
  }
]

export default route
