import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const ProductPpa = lazy(() => lazyRetry(() => import('containers/ProductPpa')))
const ProductPpaRegister = lazy(() => lazyRetry(() => import('containers/ProductPpa/register')))

const route = [
  {
    name: i18n.t('routes.productPpa.index'),
    path: '/ppa-produto',
    main: ProductPpa,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_PPA', 'ROLE_EDITAR_CADASTRO_PPA']
  },
  {
    name: i18n.t('routes.productPpa.register'),
    path: '/ppa-produto/register',
    main: ProductPpaRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_PPA']
  },
  {
    name: i18n.t('routes.productsGroup.edit'),
    path: '/ppa-produto/edit/:id',
    main: ProductPpaRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_PPA']
  }
]

export default route
