import styled from 'styled-components'

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
`

export const StyledMainFilters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > * {
    margin: 24px 24px 0 0;
  }
`
export const StyledSelect = styled.div`
  display: flex;
  width: 380px;
  margin-top: 25px;
  margin-right: 16px;
  & .Select__control {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #555a5f;
  }
  .MuiPaper-root {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    margin-top: 0px;
  }
  .MuiListItem-root {
    padding: 60px 16px;
  }
`
