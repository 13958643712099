import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form } from 'formik'
import Grid from '@material-ui/core/Grid'
import Input from 'components/Form/Input'
import Button from 'components/Button'
import * as S from './styled'

function ChangePasswordForm ({ handleSubmit, isSubmitting, handleToggleModal, userData }) {
  const { t } = useTranslation()

  const commonInputProps = {
    fullWidth: true,
    variant: 'outlined',
    disabled: isSubmitting
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <S.Text>{`${t('general.userName')}: ${userData.name}`}</S.Text>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Input
                id='justification'
                name='justification'
                label={t('general.justification')}
                fullWidth
                variant="outlined"
                multiline
                rows={5}
                inputProps={{ maxLength: 500 }}
                required
                disabled={isSubmitting}
                {...commonInputProps}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                {t('general.actions.send')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                isText
                underlined
                variant="contained"
                fontColor="#212529"
                disabled={isSubmitting}
                onClick={() => { handleToggleModal(false) }}
              >
                {t('general.actions.cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>)
}

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleToggleModal: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  userData: PropTypes.object.isRequired
}

export default ChangePasswordForm
