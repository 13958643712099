import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const PdvStoreIntegration = lazy(() => lazyRetry(() => import('containers/PdvStoreIntegration')))

const route = [
  {
    name: i18n.t('routes.pdvStoreIntegration.index'),
    path: '/pdv-store-integration',
    main: PdvStoreIntegration,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_CLIENTE_PDV', 'ROLE_EDITAR_CADASTRO_CLIENTE_PDV']
  }
]

export default route
