import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const PercentageValue = lazy(() => lazyRetry(() => import('containers/PercentageValue')))
const PercentageValueRegister = lazy(() => lazyRetry(() => import('containers/PercentageValue/register')))

const route = [
  {
    name: i18n.t('routes.percentageValue.index'),
    path: '/percentage-value',
    main: PercentageValue,
    exact: true,
    permissions: ['ROLE_CADASTRO_CONTRATO_VLPERCENTUAL_LEITURA', 'ROLE_CADASTRO_CONTRATO_VLPERCENTUAL_ESCRITA']
  },
  {
    name: i18n.t('routes.percentageValue.register'),
    path: '/cadastro-contrato-percentual/register',
    main: PercentageValueRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_CONTRATO_VLPERCENTUAL_LEITURA', 'ROLE_CADASTRO_CONTRATO_VLPERCENTUAL_ESCRITA']
  }
]

export default route
