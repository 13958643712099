import { lazy } from 'react'
import { lazyRetry } from '../help/utils'

const AnalysisVariation = lazy(() => lazyRetry(() => import('containers/AnalysisVariation')))

const route = [
  {
    path: '/report-finance',
    main: AnalysisVariation,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_REPORT_FINANCAS']
  }
]

export default route
