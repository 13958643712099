import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Subregion = lazy(() => lazyRetry(() => import('containers/Subregion')))
const SubregionRegister = lazy(() => lazyRetry(() => import('containers/Subregion/register')))

const route = [
  {
    name: i18n.t('routes.subregion.index'),
    path: '/subregion',
    main: Subregion,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_SUBREGIAO', 'ROLE_EDITAR_CADASTRO_SUBREGIAO']
  },
  {
    name: i18n.t('routes.subregion.register'),
    path: '/subregion/register',
    main: SubregionRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_SUBREGIAO']
  },
  {
    name: i18n.t('routes.subregion.edit'),
    path: '/subregion/edit/:id',
    main: SubregionRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_SUBREGIAO']
  }
]

export default route
