import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const User = lazy(() => lazyRetry(() => import('containers/User')))
const UserRegister = lazy(() => lazyRetry(() => import('containers/User/register')))
const UserConfigFirstAccess = lazy(() => lazyRetry(() => import('containers/User/containers/ConfigFirstAccess')))
const UserConfigEditionRequest = lazy(() => lazyRetry(() => import('containers/User/containers/ConfigEditionRequest')))
const UserApprovalRequest = lazy(() => lazyRetry(() => import('containers/User/containers/ApprovalRequest')))
const UserEditionRequest = lazy(() => lazyRetry(() => import('containers/User/containers/EditionRequest')))

const route = [
  {
    name: i18n.t('routes.user.index'),
    path: '/user',
    main: User,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_USUARIO', 'ROLE_EDITAR_CADASTRO_USUARIO', 'ROLE_EDITAR_CADASTRO_PRIMEIRO_ACESSO', 'ROLE_CONFIRMAR_CADASTRO_USUARIO']
  },
  {
    name: i18n.t('routes.user.register'),
    path: '/user/register',
    main: UserRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_USUARIO']
  },
  {
    name: i18n.t('routes.user.edit'),
    path: '/user/edit/:id',
    main: UserRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_USUARIO']
  },
  {
    name: i18n.t('user.configFirstAccess'),
    path: '/user/config_first_access/:id',
    main: UserConfigFirstAccess,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_PRIMEIRO_ACESSO']
  },
  {
    name: i18n.t('user.configEditionRequest'),
    path: '/user/config_edition_request/:id',
    main: UserConfigEditionRequest,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_PRIMEIRO_ACESSO']
  },
  {
    name: i18n.t('user.approvalRequest'),
    path: '/user/approval_request/:id',
    main: UserApprovalRequest,
    exact: true,
    permissions: ['ROLE_CONFIRMAR_CADASTRO_USUARIO', 'ROLE_EDITAR_CADASTRO_PRIMEIRO_ACESSO']
  },
  {
    name: i18n.t('user.editionRequest'),
    path: '/user/edition_request',
    main: UserEditionRequest,
    exact: true
  }
]

export default route
