import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Commodity = lazy(() => lazyRetry(() => import('containers/Commodity')))
const CommodityRegister = lazy(() => lazyRetry(() => import('containers/Commodity/register')))

const route = [
  {
    name: i18n.t('routes.commodity.index'),
    path: '/commodity',
    main: Commodity,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_UNIDADE_NEGOCIO',
      'ROLE_EDITAR_CADASTRO_UNIDADE_NEGOCIO']
  },
  {
    name: i18n.t('routes.commodity.register'),
    path: '/commodity/register',
    main: CommodityRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_UNIDADE_NEGOCIO']
  },
  {
    name: i18n.t('routes.commodity.edit'),
    path: '/commodity/edit/:id',
    main: CommodityRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_UNIDADE_NEGOCIO']
  }
]

export default route
