import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const TargetExecutionCategory = lazy(() => lazyRetry(() => import('containers/TargetExecutionCategory')))
const TargetExecutionCategoryRegister = lazy(() => lazyRetry(() => import('containers/TargetExecutionCategory/register')))

const route = [
  {
    name: i18n.t('routes.targetExecutionCategory.index'),
    path: '/target-execution-category',
    main: TargetExecutionCategory,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_CATEGORIAS_EXEC_METAS', 'ROLE_EDITAR_CADASTRO_CATEGORIAS_EXEC_METAS']
  },
  {
    name: i18n.t('routes.targetExecutionCategory.register'),
    path: '/target-execution-category/register',
    main: TargetExecutionCategoryRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_CATEGORIAS_EXEC_METAS']
  },
  {
    name: i18n.t('routes.targetExecutionCategory.edit'),
    path: '/target-execution-category/edit/:id',
    main: TargetExecutionCategoryRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_CATEGORIAS_EXEC_METAS']
  }
]

export default route
