import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Sandbox = lazy(() => lazyRetry(() => import('containers/Sandbox')))
const Styleguide = lazy(() => lazyRetry(() => import('containers/Sandbox/Styleguide')))
const EditContract = lazy(() => lazyRetry(() => import('containers/Agreements/AgreementsEdit')))

const route = [
  {
    name: i18n.t('routes.sandBox.index'),
    path: '/sandbox',
    main: Sandbox,
    exact: true
  },
  {
    name: i18n.t('routes.sandBox.styleguide'),
    path: '/sandbox/styleguide',
    main: Styleguide,
    exact: true
  },
  {
    name: i18n.t('routes.sandBox.EditContract'),
    path: '/sandbox/EditContract',
    main: EditContract,
    exact: true
  }
]

export default route
