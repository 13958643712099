import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Brands = lazy(() => lazyRetry(() => import('containers/Brands')))
const BrandsRegister = lazy(() => lazyRetry(() => import('containers/Brands/register')))

const route = [
  {
    name: i18n.t('routes.brands.index'),
    path: '/brands',
    main: Brands,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_MARCA', 'ROLE_EDITAR_CADASTRO_MARCA']
  },
  {
    name: i18n.t('routes.brands.register'),
    path: '/brands/register',
    main: BrandsRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_MARCA']
  },
  {
    name: i18n.t('routes.brands.edit'),
    path: '/brands/edit/:id',
    main: BrandsRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_MARCA']
  }
]

export default route
