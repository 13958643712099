/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import toast from 'common/utils/toast'
import Modal from 'components/Modal'
import RequestUserEditionForm from './RequestUserEditionForm'
import { Formik } from 'formik'
import * as yup from 'yup'
import useUser from 'common/hooks/useUser'
import userApi from 'resources/user'
import { messageServiceError } from 'help/message'

const validationSchema = yup.object().shape({
  justification: yup.string().trim().required('general.fieldRequired')
})

const initialValues = {
  justification: ''
}

function RequestUserEditionModal ({ modalState, setModalState, userData }) {
  const { t } = useTranslation()
  const { idUsuario } = useUser()
  const { openModal } = modalState

  const [submittingData, setSubmittingData] = useState(false)

  const handleToggleModal = useCallback((opened) => {
    setModalState(prevState => ({
      ...prevState,
      openModal: opened
    }))
  }, [setModalState])

  const validationText = text => (text && typeof text === 'string' && text.length)

  const sendData = useCallback((values, { setSubmitting }) => {
    const { justification } = values
    if (validationText(justification)) {
      setSubmittingData(true)
      userApi.solicitarEdicaoUsuario({
        id: idUsuario,
        solicitacaoEdicao: true,
        justificativaAcesso: justification,
        tipoAcao: 'SOLICITACAO_EDICAO'
      })
        .then(() => {
          toast.success(t('general.requestUserEditionSuccessfully'))
          handleToggleModal(false)
        })
        .catch(messageServiceError)
        .finally(() => {
          setSubmittingData(false)
          setSubmitting(false)
        })
    }
  }, [handleToggleModal, idUsuario, t])

  return openModal ? (
    <Modal
      showModal={openModal}
      toggleModal={!submittingData ? handleToggleModal : () => {}}
      disableBackdropClick={submittingData}
      modalTitle={t('general.requestUserEdition')}
      modalDescription={t('general.requestUserEditionDescribe')}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={sendData}>
        {props => <RequestUserEditionForm {...props} handleToggleModal={handleToggleModal} userData={userData} />}
      </Formik>
    </Modal>) : null
}

RequestUserEditionModal.propTypes = {
  modalState: PropTypes.shape({
    openModal: PropTypes.bool,
    data: PropTypes.object
  }).isRequired,
  setModalState: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired
}

export default RequestUserEditionModal
