import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Segment = lazy(() => lazyRetry(() => import('containers/Segment')))
const SegmentRegister = lazy(() => lazyRetry(() => import('containers/Segment/register')))

const baseUrl = '/segment'

const route = [
  {
    name: i18n.t('routes.segment.index'),
    path: baseUrl,
    main: Segment,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_SEGMENTO']
  },
  {
    name: i18n.t('routes.segment.register'),
    path: `${baseUrl}/register`,
    main: SegmentRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_SEGMENTO']
  },
  {
    name: i18n.t('routes.segment.edit'),
    path: `${baseUrl}/edit/:id`,
    main: SegmentRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_SEGMENTO']
  }
]

export default route
