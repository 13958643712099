/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'

const getAppBarColor = () => {
  const appBarColor = {
    QA: '#333333',
    HML: '#40A624',
    PRD: '#032587',
    LOCAL: '#D56F41'
  }
  switch (window.location.hostname) {
    case 'localhost':
      return appBarColor.LOCAL
    case 'sistemagpc-moises-qa.softbox.com.br':
      return appBarColor.QA
    case 'sistemagpc-moises-hml.softbox.com.br':
      return appBarColor.HML
    default:
      return appBarColor.PRD
  }
}

export const StyledTitle = styled(Typography)`
  color: #8491a5;
  font-style: normal;
  font-size: 18px;
  line-height: normal;
  padding-left: 32px;
`

export const StyledListItem = styled(ListItem)`
  && {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  font-familly: "Roboto";
  font-weight: 400;
  color: ${({ selected }) => (selected ? '#032587' : '#555a5f')};
  ${({ selected }) => selected &&
    css`
      border-left: 4px solid #0834b5;
    `}
`

export const StyledRouteTitle = styled(Typography)`
  flex-grow: 1;
  margin-left: 30px;
  color: #011738;
`

export const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  background-color: ${getAppBarColor};
  color: #ffffff;
`

export const StyledDrawer = styled(forwardRef(({ ...props }, ref) => <Drawer {...props} ref={ref} />))`
  position: relative;
  z-index: 1000;
  && .MuiDrawer-paper {
    padding-top: 75px;
    width: ${({ open }) => (open ? '320px' : '58px')};
    flex-shrink: 0;
    white-space: 'nowrap';
    overflow-y: auto;
    overflow-x: hidden;
    transition: width 350ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    ::-webkit-scrollbar {
      width: 1px;
      background-color: #dadada;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #8492a6;
      height: 40px;
    }
  }
  
`

export const StyledTitleItem = styled(Typography)`
  font-size: 16px;
  line-height: normal;
  padding-left: 10px;
`
export const StyledContainerMenu = styled.div`
  margin-left: 46px;
`

export const StyledAccountCircleIcon = styled(AccountCircleIcon)`
  font-size: calc(53px * 0.7);
  color: #FFFFFF;
`

export const StyledUserName = styled(Typography)`
  color: #FFFFFF;
`

export const StyledTradeMarkContainer = styled(({ ...props }) => <Grid item {...props} />)`
  flex: 1;
  justify-self: flex-start;
  svg {
    max-height: calc(53px * 0.7);
    width: auto;
  }
`

export const StyledToolbar = styled(Toolbar)`
  padding: 0 10px;
`

export const StyledImageContainer = styled.div`
  border-left: 1px solid white;
  margin-left: 10px;
  padding-left: 10px;
  img {
    max-height: calc(53px * 0.7);
    width: auto;
  }
`

export const StyledTradeMarkBox = styled(Grid)`
  cursor: pointer;
`

