import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const PpaProduct = lazy(() => lazyRetry(() => import('containers/PpaProduct')))

const route = [
  {
    name: i18n.t('routes.ppaProduct.index'),
    path: '/report-products',
    main: PpaProduct,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_RELATORIO_PRODUTOS']
  }
]

export default route
