import { parseJwt, hasPermission, hasAnyPermission } from 'help/jwt'
import { useStore } from 'components/Store'
import _get from 'lodash/get'

function useUser() {
  const { store } = useStore('AUTH')
  // const accessToken = window.localStorage.getItem('access_token')
  const { access_token: accessToken } = store
  const idUsuario = _get(parseJwt(accessToken), 'id', null)

  const hasRole = role => hasPermission(accessToken, role)

  const hasAnyRole = roles => hasAnyPermission(accessToken, roles)

  return {
    accessToken,
    idUsuario,
    hasRole,
    hasAnyRole
  }
}

export default useUser
