import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Migration = lazy(() => lazyRetry(() => import('containers/Migration')))

const route = [
  {
    name: i18n.t('routes.migration.index'),
    path: '/migration',
    main: Migration,
    exact: true,
    permissions: [
      'ROLE_APROVAR_MIGRACAO_CLIENTE_ON',
      'ROLE_EDITAR_MIGRACAO_CLIENTE_ON',
      'ROLE_VISUALIZAR_MIGRACAO_CLIENTE_ON'
    ]
  }
]

export default route
