// import { lazy } from 'react'
// import i18n from 'common/i18n'

// const Cva = lazy(() => import('containers/Cva'))
// const CvaRegister = lazy(() => import('containers/Cva/register'))

const route = [
  // {
  //   name: i18n.t('routes.cva.index'),
  //   path: '/cva',
  //   main: Cva,
  //   exact: true,
  //   permissions: ['ROLE_VISUALIZAR_CADASTRO_CVA', 'ROLE_EDITAR_CADASTRO_CVA']
  // },
  // {
  //   name: i18n.t('routes.cva.register'),
  //   path: '/cva/register',
  //   main: CvaRegister,
  //   exact: true,
  //   permissions: ['ROLE_EDITAR_CADASTRO_CVA']
  // },
  // {
  //   name: i18n.t('routes.cva.edit'),
  //   path: '/cva/edit/:id',
  //   main: CvaRegister,
  //   exact: true,
  //   permissions: ['ROLE_EDITAR_CADASTRO_CVA']
  // }
]

export default route
