import React from 'react'
import { RadioGroup } from '@material-ui/core'
import { withFieldRadio } from '../Field'
import FormControlRadio from 'components/Form/FormControlRadio'

function RadioGroupFormControl (props) {
  return (
    <FormControlRadio {...props} customComponent={RadioGroup} />
  )
}
export { RadioGroup, RadioGroupFormControl }
export default withFieldRadio(RadioGroup)
