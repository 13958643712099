import styled from 'styled-components'
import { Paper } from '@material-ui/core'

export const StyledContainer = styled.div`
  background-color: #66BBFF;
  width: 100vw;
  height: 100vh;
`

export const StyledBox = styled(Paper)`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 48px;
  width: 464px;
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
  margin: auto;
`

export const StyledGPCImage = styled.img`
  width: 92px;
  max-width: 100%;
  margin-bottom: 36px;
`

export const StyledChromeImageContainer = styled.figure`
  margin: 0px;
  width: 100%;
  height: 52px;
`

export const StyledChromeImage = styled.img`
  width: 52px;
  min-width: 52px;
  max-width: 100%;
  max-height: 100%;
`

export const StyledTextContainer = styled.div`
  margin-bottom: 20px;
`

export const StyledText = styled.p`
  font-size: 16px;
  color: ${props => props.color ? props.color : '#000'};
  text-align: justify;
  line-height: 1.4;
`

export const StyledTextStrong = styled.strong`
  padding: 0px;
`

export const StyledLink = styled.a`
  display: flex;
  align-content: center;
  color: #000;
  text-decoration: none !important;

  :hover {
    color: #021d6c;
    font-weight: 500;
  }
`

export const StyledLinkText = styled.span`
  color: #032587;
  text-transform: uppercase;
  padding-left: 12px;
  height: 16px;
  margin: auto 0;
`
