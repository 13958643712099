import router from 'common/utils/router'
import { get } from 'common/utils/object'
import { axiosCreate } from './apiConfig'
import Api from './apiGPC'
import { buildRequestParamsObj } from 'help/requests'

export const onResponseError = (error) => {
  const response = get(error, 'response', {})
  const status = get(response, 'status')

  if (status === 401) {
    window.localStorage.setItem('expiredToken', true)
    router.push('/login')
  }

  if (status === 503 || !status) {
    const health = {
      path: `${process.env.REACT_APP_PATH}actuator/health`,
      config: buildRequestParamsObj(null, Api.getHeaders(), 'GET')
    }
    const info = {
      path: `${process.env.REACT_APP_PATH}actuator/info`,
      config: buildRequestParamsObj(null, Api.getHeaders(), 'GET')
    }
    axiosCreate(health.path, health.config).request().then(data => data).catch((errorHealth) => {
      console.error(errorHealth)
      axiosCreate(info.path, info.config).request().then(data => data).catch((errorInfo) => {
        console.error(errorInfo)
        if (window.location.pathname !== '/service-unavailable') {
          window.location.href = `/service-unavailable?backTo=${btoa(window.location.pathname)}`
        }
      })
    })
  }

  return Promise.reject(error.response || error)
}

export const validateRequest = async (config) => {
  if (config.data && config.data.constructor.name === 'Object') {
    const result = Object.keys(config.data).reduce((acc, cur) => {
      if (
        config.data[cur] !== null &&
        config.data[cur] !== undefined
      ) {
        acc[cur] = config.data[cur]
      }
      return acc
    }, {})

    config.data = result
  }
  return config
}
