import styled, { css } from 'styled-components'

const StyledMultiSelectLabel = styled.label`
  position: absolute;
  top: 0px;
  left: 0px;
  transform: ${({ isSelected }) => (isSelected ? 'translate(0px, -8px) scale(0.7)' :
    'translate(14px, 10px) scale(1)')};
  ${({ isDisabled }) => !isDisabled && css`background: white;`}
  ${({ isSelected, isDisabled }) => isSelected && isDisabled && css`background: linear-gradient(to bottom, #fff 45%, #D7D7D7 55%);`}
  ${({ required }) => required && css`
    &:after {
      content: "*";
    }    
  `}
  padding: 0 1px;
  transition: all 0.3s ease;
  z-index: 1;
  line-height: 1;
  color: ${({ error }) => (error ? '#eb0000' : '#555a5f')};
  font-size: 16px;
  pointer-events: none;
  white-space: nowrap;
`

const StyledMultiSelect = styled.div`
  position: relative;
  &:focus-within ${StyledMultiSelectLabel} {
    transform: translate(4px, -8px) scale(0.7);
  }
  .css-yk16xz-control, .css-yk16xz-control:hover {
    border-color: ${({ error }) => (error ? '#eb0000' : '#555a5f')};
    box-sizing: border-box;
    min-height: 40px;
  }
  ${({ isDisabled }) => (isDisabled && css`
      .css-1fhf3k1-control {
        background: #D7D7D7;
        min-height: 40px;
        border: 1px solid #555A5F;
      }
    `)}
}
  .css-1pahdxg-control, .css-1pahdxg-control:hover {
    border: 2px solid #555a5f;
    outline: none;
    box-shadow: none;
  }
  .css-1okebmr-indicatorSeparator {
    background-color: #555a5f;
  }
  .css-tlfecz-indicatorContainer svg {
    fill: #555a5f;  
  }
  .css-yt9ioa-option, .css-1n7v3ny-option {
    text-align: left;
    padding: 6px 16px;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    &:hover {
      background-color: #DBDBDB;
    }
  }
  .css-1n7v3ny-option {
    background-color: #DBDBDB;
  }
  .css-26l3qy-menu {
    z-index: 3;
    margin-top: 0px;
  }
  ${({ labelWidth }) => css`min-width: ${labelWidth}px;`}
`

export {
  StyledMultiSelect,
  StyledMultiSelectLabel
}
