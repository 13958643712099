import styled, { css } from 'styled-components'
import { Typography } from '@material-ui/core'

export const StyledTitlePage = styled(Typography)`
  color: #212529;
  font-size: 18px;
  font-weight: 300;
  margin-top: 24px;
  background: #fff;
  display: block;
  width: 380px;
`

export const StyledEventList = styled.div`
  display: block;
  align-items: center;
  padding-left: 12px;
  margin-top: ${({ isReminders }) => (isReminders ? '0' : '12px')}
  overflow: hidden auto;
  max-height: ${({ isReminders }) => (isReminders ? '260px' : 'calc(100vh - 446px)')}
`

export const StyledRoot = styled.div`
  width: 100%;
  max-width: 360;
  position: relative;
  max-height: 450px;

  .container:nth-last-child(2) div:last-child {
    border-bottom: none;
  }
`

export const StyledEventDay = styled.div`
  margin-top: 24px;
  font-size: 14px;
  font-weight: 500;
  color: #212529;
  display: ${({ isSingleDateEvents }) => (isSingleDateEvents ? 'none' : 'block')}
`

export const StyledEventsOfDay = styled.div`
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-left: -12px;
  padding-left: 12px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  & > * {
    margin: 12px 0;
  }
`

export const StyledEventItem = styled.div`
  display: flex;
  align-items: center;
`

export const StyledEventBullet = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  ${({ color }) => css`background-color: ${color};`}
`

export const StyledEventDescription = styled.span`
  margin-left: 4px;
  font-weight: 300;
  font-size: 14px;
  color: #212529;
`
export const StyledEmptyState = styled.div`
  display: ${({ isEmpty }) => (isEmpty ? 'flex' : 'none')}
  align-items: center;
  height: 50px;

  span {
    margin-left: 8px;
  }
`
