import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Sku = lazy(() => lazyRetry(() => import('containers/Sku')))
const SkuRegister = lazy(() => lazyRetry(() => import('containers/Sku/register')))

const route = [
  {
    name: i18n.t('routes.sku.index'),
    path: '/sku',
    main: Sku,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_SKU', 'ROLE_EDITAR_CADASTRO_SKU']
  },
  {
    name: i18n.t('routes.sku.register'),
    path: '/sku/register',
    main: SkuRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_SKU']
  },
  {
    name: i18n.t('routes.sku.edit'),
    path: '/sku/edit/:id',
    main: SkuRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_SKU']
  }
]

export default route
