import styled, { css } from 'styled-components'
import { Typography } from '@material-ui/core'

const StyledTitlePage = styled(Typography)`
  color: #032587;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.6;
  margin-top: 20px;
  margin-left: 35px;
  background: #fff;
`

const StyledCalendarWrapper = styled.div`
  position: fixed;
  ${({ isOpen }) => (isOpen ? css`right: -24px;` : css`right: -322px;`)}
  top: 0;
  height: 100vh;
  padding-top: 53px;
  width: 322px;
  transition: all 0.5s ease;
  z-index: 999;
  background: #fff;
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.11);
  ${({ isOpen }) => (isOpen ? css`margin-right: 8px;` : 'none')};
`

const StyledCalendarToggleButton = styled.div`
  ${({ isOpen }) => (isOpen ? `
      width: 40px;
      border-radius: 20px;
      left: -20px;
      justify-content: center;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.24);
      border: solid 2px #ffffff;
  ` : `
      width: 84px;
      border-radius: 20px 0 0 20px;
      padding-left: 4px;
      left: -84px;
      & :first-child {
          margin-right: 8px;
      }
  `)}
  height: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  top: -12px;
  margin-top: 80px;
  background: #fff;

`

const StyledCalendarContainer = styled.div`
  height: 100%;
  overflow: scroll;
  background: #fff;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.11);
`
const StyledCalendar = styled.div`
  display: flex;
  width: 100px;
  height: 260px;
  margin-top: 7px; 

  .MuiPickersStaticWrapper-staticWrapperRoot {
    overflow: visible;
  }
`

const StyledWrapperDayComponent = styled.div`
  position: absolute;
  bottom: 5px;
  display: flex;
  left: 50%;
  transform: translate(-50%);
`

const StyledDayComponent = styled.div`
  background-color: ${({ cor }) => cor || '#FFF'}
  height: 4px;
  width: 4px;
  border-radius: 2px;
  margin: 0 1px;
`

const StyledContainer = styled.div`
  position: relative;
`

export {
  StyledCalendarWrapper,
  StyledCalendarToggleButton,
  StyledCalendarContainer,
  StyledTitlePage,
  StyledCalendar,
  StyledDayComponent,
  StyledWrapperDayComponent,
  StyledContainer
}
