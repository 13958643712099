import api from './base'

const baseApi = api(process.env.REACT_APP_PATH)
const baseApiMock = api(process.env.REACT_APP_MOCK)

const Api = {
  request(path, options) {
    return baseApi.request(path, options)
  },
  requestMock(path, options) {
    return baseApiMock.request(path, options)
  },
  getHeaders() {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('access_token')}`
    }
    return headers
  },
  getFileHeaders() {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'content-type': 'multipart/form-data',
      Authorization: `Bearer ${window.localStorage.getItem('access_token')}`
    }
    return headers
  },
  getLoginHeaders() {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic c29mdGJveF9ncGMyOnNmdGJ4Z3Bj'
    }
    return headers
  }
}

export default Api
