import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const InnovationProducts = lazy(() => lazyRetry(() => import('containers/InnovationProducts')))
const InnovationProductsRegister = lazy(() => lazyRetry(() => import('containers/InnovationProducts/register')))

const route = [
  {
    name: i18n.t('routes.innovationProducts.index'),
    path: '/innovation-products',
    main: InnovationProducts,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_PRODUTO_INOVACAO']
  },
  {
    name: i18n.t('routes.innovationProducts.register'),
    path: '/innovation-products/register',
    main: InnovationProductsRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_PRODUTO_INOVACAO']
  },
  {
    name: i18n.t('routes.innovationProducts.edit'),
    path: '/innovation-products/edit/:id',
    main: InnovationProductsRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_PRODUTO_INOVACAO']
  }
]

export default route
