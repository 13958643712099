import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const AppreciationFinanceVolume = lazy(() => lazyRetry(() => import('containers/AppreciationFinanceVolume')))
const AppreciationCategoryPercentage = lazy(() => lazyRetry(() => import('containers/AppreciationCategoryPercentage')))

const route = [
  {
    name: i18n.t('routes.appreciation.financeVolume'),
    path: '/appreciation/financeVolume',
    main: AppreciationFinanceVolume,
    exact: true,
    permissions: ['ROLE_EDITAR_VALORIZACAO_PASTA', 'ROLE_VISUALIZAR_VALORIZACAO_PASTA']
  },
  {
    name: i18n.t('routes.appreciation.categoryPercentage'),
    path: '/appreciation/categoryPercentage',
    main: AppreciationCategoryPercentage,
    exact: true,
    permissions: ['ROLE_EDITAR_VALORIZACAO_PASTA', 'ROLE_VISUALIZAR_VALORIZACAO_PASTA']
  }
]

export default route
