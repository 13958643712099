import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Sellin = lazy(() => lazyRetry(() => import('containers/Sellin')))

const baseUrl = '/sell-in-integration'

const route = [
  {
    name: i18n.t('routes.sellin.index'),
    path: baseUrl,
    main: Sellin,
    exact: true,
    permissions: ['ROLE_ACESSO_SELLIN_INTEGRACAO']
  }
]

export default route
