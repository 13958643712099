// import { lazy } from 'react'
// import i18n from 'common/i18n'

// const Channel = lazy(() => import('containers/Channel'))
// const ChannelRegister = lazy(() => import('containers/Channel/register'))

const route = [
  // {
  //   name: i18n.t('routes.channel.index'),
  //   path: '/channel',
  //   main: Channel,
  //   exact: true,
  //   permissions: ['ROLE_VISUALIZAR_CADASTRO_CANAL', 'ROLE_EDITAR_CADASTRO_CANAL']
  // },
  // {
  //   name: i18n.t('routes.channel.register'),
  //   path: '/channel/register',
  //   main: ChannelRegister,
  //   exact: true,
  //   permissions: ['ROLE_EDITAR_CADASTRO_CANAL']
  // },
  // {
  //   name: i18n.t('routes.channel.edit'),
  //   path: '/channel/edit/:id',
  //   main: ChannelRegister,
  //   exact: true,
  //   permissions: ['ROLE_EDITAR_CADASTRO_CANAL']
  // }
]

export default route
