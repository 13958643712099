import React, { useState, useEffect, useCallback } from 'react'
import { useInterval } from 'react-use'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _groupBy from 'lodash/groupBy'
import _map from 'lodash/map'
import { DatePicker } from '@material-ui/pickers'
import TodayIcon from '@material-ui/icons/Today'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import * as moment from 'moment'
import {
  StyledCalendarWrapper,
  StyledCalendarToggleButton,
  StyledCalendarContainer,
  StyledTitlePage,
  StyledCalendar,
  StyledWrapperDayComponent,
  StyledDayComponent,
  StyledContainer
} from './styled'
import Filters, { initialValues } from './Filters'
import EventList from '../EventList'
import calendarApi from 'resources/calendar'
import { messageServiceError } from 'help/message'

function Calendar() {
  const [isOpen, setOpen] = useState(false)
  const [eventOptions, setEventOptions] = useState([])
  const [events, setEvents] = useState([])
  const [persistEvents, setPerstistEvents] = useState([])
  const [eventsSelected, setEventsSelected] = useState({ events: [], isSingleDateEvents: false, dateSelected: moment() })
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()

  const handleFilterChange = useCallback((data) => {
    if (!data.eventTypes) {
      return setEvents(persistEvents)
    }
    const eventsFiltered = _filter(persistEvents, item => item.evento === data.eventTypes.value)
    return setEvents(eventsFiltered)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistEvents])

  useInterval(() => {
    calendarApi.getListEvents()
      .then(res => setEventOptions(res.data.map(item => ({ label: item, value: item }))))
      .catch(messageServiceError)

    calendarApi.fetchListingData()
      .then((res) => {
        setEvents(_get(res, 'data.lista', []))
        setPerstistEvents(_get(res, 'data.lista', []))
      })
      .catch(messageServiceError)
  }, isOpen ? 2000 : null)

  useEffect(() => {
    setLoading(false)
  }, [events])

  const handleChange = (date) => {
    const newEventsSelected = _filter(events, evt => moment(evt.dataEvento, 'DD/MM/YYYY').format('DD/MM/YYYY') === moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY'))
    setEventsSelected(() => ({
      isSingleDateEvents: true,
      events: newEventsSelected,
      dateSelected: date
    }))
    setCalendarDate(date)
  }

  const customRenderDay = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
    const eventList = events.filter(
      event => event.dataEvento === moment(day).format('DD/MM/YYYY')
    )

    const groupEventList = _groupBy(eventList, 'cor')

    const newList = _map(groupEventList, (items, color) => ({
      cor: color
    }))

    if (!dayInCurrentMonth || !eventList.length) {
      return dayComponent
    }

    return (
      <StyledContainer>
        {dayComponent}
        <StyledWrapperDayComponent>
          {
            newList.map((item, key) => (
              <StyledDayComponent
                key={key}
                className="eventName"
                title="eventName"
                cor={item.cor}
              />
            ))
          }
        </StyledWrapperDayComponent>
      </StyledContainer>
    )
  }

  return (
    <StyledCalendarWrapper isOpen={isOpen}>
      <StyledTitlePage> {t('general.activityCalendar')}</StyledTitlePage>
      <Formik initialValues={initialValues} onSubmit={handleFilterChange}>
        {props => (
          <Filters
            {...props}
            filterData={eventOptions}
            handleChange={handleFilterChange}
          />
        )}
      </Formik>
      <StyledCalendar>
        <DatePicker
          id="calendar"
          autoOk
          disableToolbar
          orientation="portrait"
          variant="static"
          openTo="date"
          value={calendarDate}
          onChange={handleChange}
          renderDay={customRenderDay}
        />
      </StyledCalendar>
      <EventList allEvents={events} loading={loading} eventsSelected={eventsSelected} />
      <StyledCalendarToggleButton onClick={() => setOpen(prev => !prev)} isOpen={isOpen}>
        <ChevronLeftIcon color="primary" style={{ transition: 'all 0.8s linear', transform: isOpen ? 'rotate(180deg)' : '' }}/>
        {
          !isOpen && <TodayIcon color="primary"/>
        }
      </StyledCalendarToggleButton>
      <StyledCalendarContainer>
      </StyledCalendarContainer>
    </StyledCalendarWrapper>
  )
}

export default Calendar
