// import { lazy } from 'react'
// import i18n from 'common/i18n'

// const EconomicGroup = lazy(() => import('containers/EconomicGroup'))
// const EconomicGroupRegister = lazy(() => import('containers/EconomicGroup/register'))

const route = [
  // {
  //   name: i18n.t('routes.economicGroup.index'),
  //   path: '/economic-group',
  //   main: EconomicGroup,
  //   exact: true,
  //   permissions: ['ROLE_EDITAR_CADASTRO_GRUPO_ECONOMICO', 'ROLE_VISUALIZAR_CADASTRO_GRUPO_ECONOMICO']
  // },
  // {
  //   name: i18n.t('routes.economicGroup.register'),
  //   path: '/economic-group/register',
  //   main: EconomicGroupRegister,
  //   exact: true,
  //   permissions: ['ROLE_EDITAR_CADASTRO_GRUPO_ECONOMICO']
  // },
  // {
  //   name: i18n.t('routes.economicGroup.edit'),
  //   path: '/economic-group/edit/:id',
  //   main: EconomicGroupRegister,
  //   exact: true,
  //   permissions: ['ROLE_EDITAR_CADASTRO_GRUPO_ECONOMICO']
  // }
]

export default route
