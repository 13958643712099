import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Category = lazy(() => lazyRetry(() => import('containers/Category')))
const CategoryRegister = lazy(() => lazyRetry(() => import('containers/Category/register')))

const route = [
  {
    name: i18n.t('routes.category.index'),
    path: '/category',
    main: Category,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_CATEGORIA', 'ROLE_EDITAR_CADASTRO_CATEGORIA']
  },
  {
    name: i18n.t('routes.category.register'),
    path: '/category/register',
    main: CategoryRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_CATEGORIA']
  },
  {
    name: i18n.t('routes.category.edit'),
    path: '/category/edit/:id',
    main: CategoryRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_CATEGORIA']
  }
]

export default route
