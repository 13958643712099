import React from 'react'
import { Router } from 'react-router-dom'
import { toast } from 'react-toastify'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { StylesProvider } from '@material-ui/styles'
import theme from 'common/constants/theme'
import { history } from 'common/utils/router'
import { AppBar } from 'components'
import StoreProvider from 'components/Store/StoreProvider'
import Juggernaut from 'containers/Juggernaut'
import InvalidBrowserPage from 'containers/InvalidBrowserPage'
import Main from 'containers/Main'
import 'react-toastify/dist/ReactToastify.css'
import 'common/utils/moment'
import { getDetectBrowserHelper, browserNames } from 'common/utils/detectBrowsers'
import ErrorBoundary from 'components/ErrorBoundary/index'

toast.configure()
const { isValidBrowser } = getDetectBrowserHelper(window)

const App = () => (
  isValidBrowser(browserNames.CHROME_NAME, true) ? (
    <ErrorBoundary>
      <StylesProvider injectFirst>
        <StoreProvider blackList={['LEVER_RECOMMENDATION', 'LEVER_EDIT', 'CYCLE_FOLDER_COUNTING']}>
          <Juggernaut theme={theme}>
            <Router history={history}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <AppBar />
                <Main />
              </MuiPickersUtilsProvider>
            </Router>
          </Juggernaut>
        </StoreProvider>
      </StylesProvider>
    </ErrorBoundary>
  ) : (<InvalidBrowserPage/>)
)

export default App
