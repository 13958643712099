import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Calendar = lazy(() => lazyRetry(() => import('containers/Calendar')))

const route = [
  {
    name: i18n.t('routes.calendar.index'),
    path: '/calendar',
    main: Calendar,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_CALENDARIO', 'ROLE_VISUALIZAR_CADASTRO_CALENDARIO']
  }
]

export default route
