import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const NewFlag = lazy(() => lazyRetry(() => import('containers/NewFlag')))

const route = [
  {
    name: i18n.t('routes.newFlag.index'),
    path: '/new-flag',
    main: NewFlag,
    exact: true
  }
]

export default route
