import React, { useState, useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import { getIn } from 'formik'
import _map from 'lodash/map'
import _get from 'lodash/get'
import i18n from 'common/i18n'
import { StyledChipBody, StyledChipText } from '../Chip/style'
import withField from '../Form/Field/withField'
import { StyledMultiSelect, StyledMultiSelectLabel } from './styled'
import { getRootName } from 'components/Form/Field/utils'

const INDICATOR_SIZE = 60

const MultiValueLabel = ({ data }) => (
  <StyledChipText>{data.label}</StyledChipText>
)

MultiValueLabel.propTypes = {
  data: PropTypes.object.isRequired
}

const MultiValueContainer = props => (
  <StyledChipBody>
    <components.MultiValueContainer {...props} />
  </StyledChipBody>
)

function ValueContainer(props) {
  const { children, getValue, options } = props

  const { t } = useTranslation()

  const optionsSelectedCount = _get(getValue(), 'length')

  const getMessage = () => {
    if (optionsSelectedCount !== 1) {
      if (optionsSelectedCount === options.length - 1) {
        return t('general.all')
      }
      return `${optionsSelectedCount} ${t('general.options')} ${t('general.selectedF')}s`
    }
    return `${optionsSelectedCount} ${t('general.option')} ${t('general.selectedF')}`
  }

  return (
    <components.ValueContainer {...props}>
      {optionsSelectedCount > 0 &&
        getMessage().toUpperCase()}
      {React.cloneElement(children[1])}
    </components.ValueContainer>
  )
}

ValueContainer.propTypes = {
  children: PropTypes.node.isRequired,
  getValue: PropTypes.func.isRequired,
  selectProps: PropTypes.object,
  options: PropTypes.array
}

ValueContainer.defaultProps = {
  selectProps: {},
  options: []
}

function MultiSelect({
  label,
  options,
  field,
  form,
  isMulti,
  handleFieldChange,
  validateOnBlur,
  isDisabled,
  hideSelectedOptions,
  showTooltip,
  noOptionsMessage,
  inputProps,
  required,
  menuPlacement,
  menuPosition,
  closeMenuOnSelect,
  nullIfEmpty,
  allowSelectAll,
  allOption,
  showCountSelect,
  dataTestId,
  ...others
}) {
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)

  const { t } = useTranslation()

  const isSelectAllOption = option => allowSelectAll && isMulti && option && option[option.length - 1]?.value === allOption.value

  const handleOnChange = (option) => {
    const value = nullIfEmpty && (option === null || option.length === 0) ? null : option
    form.setFieldValue(field.name, isSelectAllOption(option) ? options : value)
    if (handleFieldChange) {
      handleFieldChange(option)
    }
  }

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth + INDICATOR_SIZE)
  }, [])

  const selectedValues = _map(others.value, el => (el ? el.label : ''))
  const MaxLengthInput = props => (
    <components.Input {...props} {...inputProps} />
  )

  const Input = !isMulti ? { Input: MaxLengthInput } : {}
  const name = getRootName(field.name)
  const internalError = getIn(form.errors, field.name)
  const rootError = getIn(form.errors, name)
  const errorToValidate = typeof rootError === 'string' ? rootError : internalError
  const error = !!errorToValidate && others.error

  const tooltipText = useMemo(() => {
    if (isMulti) {
      return selectedValues.toString() || t('general.noneOptionSelected')
    }
    const selectedValue = _get(others, 'value', null)
    return _get(selectedValue, 'label', '')
  }, [isMulti, others, selectedValues, t])

  const getOptions = () => {
    const allOptionsLength = _get(options, 'length', 0)
    const selectedOptionsLength = _get(field, 'value.length', 0)
    if (allOptionsLength && allowSelectAll && allOptionsLength !== selectedOptionsLength) {
      return [allOption, ...options]
    }
    return options
  }

  return (
    <div dataTestId={dataTestId}>
      <StyledMultiSelect error={error} isDisabled={isDisabled} labelWidth={labelWidth} title={tooltipText}>
        <StyledMultiSelectLabel error={error} isDisabled={isDisabled} isSelected={field.value} ref={inputLabel} required={required}>
          {label}
        </StyledMultiSelectLabel>
        <Select
          {...others}
          closeMenuOnSelect={closeMenuOnSelect || !isMulti || isDisabled}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          components={{
            MultiValueLabel,
            MultiValueContainer,
            ValueContainer: showCountSelect ? ValueContainer : components.ValueContainer,
            ...Input
          }}
          isMulti={isMulti}
          name={field.name}
          value={field.value}
          placeholder=""
          isDisabled={isDisabled}
          onChange={handleOnChange}
          onBlur={validateOnBlur ? field.onBlur : () => { }}
          options={getOptions()}
          menuPortalTarget={document.querySelector('body')}
          noOptionsMessage={noOptionsMessage}
          menuPlacement={menuPlacement}
          menuPosition={menuPosition}
        />
      </StyledMultiSelect>
    </div>
  )
}

MultiSelect.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  isMulti: PropTypes.bool,
  required: PropTypes.bool,
  validateOnBlur: PropTypes.bool,
  isDisabled: PropTypes.bool,
  handleFieldChange: PropTypes.func,
  hideSelectedOptions: PropTypes.bool,
  showTooltip: PropTypes.bool,
  inputProps: PropTypes.object,
  noOptionsMessage: PropTypes.func,
  menuPlacement: PropTypes.string,
  menuPosition: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  nullIfEmpty: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  showCountSelect: PropTypes.bool,
  dataTestId: PropTypes.string
}

MultiSelect.defaultProps = {
  isDisabled: false,
  required: false,
  isMulti: true,
  label: i18n.t('general.select'),
  validateOnBlur: false,
  options: [],
  handleFieldChange: () => { },
  hideSelectedOptions: false,
  showTooltip: false,
  inputProps: { maxLength: 50 },
  noOptionsMessage: () => i18n.t('general.noOptions'),
  menuPlacement: 'auto',
  menuPosition: 'absolute',
  closeMenuOnSelect: false,
  nullIfEmpty: false,
  allOption: {
    label: i18n.t('general.actions.selectAll').toUpperCase(),
    value: '*'
  },
  allowSelectAll: false,
  showCountSelect: false,
  dataTestId: null
}

export default withField(MultiSelect)
