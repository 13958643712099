import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const BudgetType = lazy(() => lazyRetry(() => import('containers/BudgetType')))
const BudgetTypeRegister = lazy(() => lazyRetry(() => import('containers/BudgetType/register')))

const route = [
  {
    name: i18n.t('routes.budgetType.index'),
    path: '/budget-type',
    main: BudgetType,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_TIPO_VERBA', 'ROLE_EDITAR_CADASTRO_TIPO_VERBA']
  },
  {
    name: i18n.t('routes.budgetType.register'),
    path: '/budget-type/register',
    main: BudgetTypeRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_TIPO_VERBA']
  },
  {
    name: i18n.t('routes.budgetType.edit'),
    path: '/budget-type/edit/:id',
    main: BudgetTypeRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_TIPO_VERBA']
  }
]

export default route
