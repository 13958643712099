import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Lever = lazy(() => lazyRetry(() => import('containers/Lever')))
const LeverRegister = lazy(() => lazyRetry(() => import('containers/Lever/LeverRegister')))
const LeverEdit = lazy(() => lazyRetry(() => import('containers/Lever/LeverEdit')))
const LeverEditRecommendations = lazy(() => lazyRetry(() => import('containers/Lever/LeverEditRecommendations')))
const LeverEditAuditedsProducts = lazy(() => lazyRetry(() => import('containers/Lever/LeverEditAuditedsProducts')))
const LeverDetailsReport = lazy(() => lazyRetry(() => import('containers/LeverDetailsReport')))
const LeverAbstractReport = lazy(() => lazyRetry(() => import('containers/LeverAbstractReport')))

const route = [
  {
    name: i18n.t('routes.lever.index'),
    path: '/lever',
    main: Lever,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_ALAVANCA', 'ROLE_VISUALIZAR_CADASTRO_ALAVANCAS']
  },
  {
    name: i18n.t('routes.lever.register'),
    path: '/lever/register',
    main: LeverRegister,
    exact: false,
    permissions: ['ROLE_EDITAR_CADASTRO_ALAVANCA']
  },
  {
    name: i18n.t('routes.lever.edit'),
    path: '/lever/edit/:id',
    main: LeverEdit,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_ALAVANCA']
  },
  {
    name: i18n.t('routes.lever.recommendationsEdit'),
    path: '/lever/recommendations-edit',
    main: LeverEditRecommendations,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_ALAVANCA']
  },
  {
    name: i18n.t('routes.lever.auditedsProductsEdit'),
    path: '/lever/auditeds-products-edit',
    main: LeverEditAuditedsProducts,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_ALAVANCA']
  },
  {
    name: i18n.t('routes.lever.detailsReport'),
    path: '/lever/details-report',
    main: LeverDetailsReport,
    exact: true,
    permissions: ['ROLE_RELATORIO_COMPLIANCE_LOJAS_ACORDOS']
  },
  {
    name: i18n.t('routes.lever.abstractReport'),
    path: '/lever/abstract-report',
    main: LeverAbstractReport,
    exact: true,
    permissions: ['ROLE_RELATORIO_COMPLIANCE_LOJAS_ACORDOS']
  }
]

export default route
