import React, { memo } from 'react'
import PropTypes from 'prop-types'
import _groupBy from 'lodash/groupBy'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _sortBy from 'lodash/sortBy'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import CalendarTodayTwoToneIcon from '@material-ui/icons/CalendarTodayTwoTone'
import {
  StyledEventList,
  StyledTitlePage,
  StyledRoot,
  StyledEventDay,
  StyledEventsOfDay,
  StyledEventItem,
  StyledEventBullet,
  StyledEventDescription,
  StyledEmptyState
} from './styled'

const formatEvents = (events, isSingleDateEvents) => {
  if (!isSingleDateEvents) {
    const newEvents = _filter(events, (event) => {
      const currentDate = moment().format('DD/MM/YYYY')
      return moment(event.dataEvento, 'DD/MM/YYYY').valueOf() >= moment(currentDate, 'DD/MM/YYYY').valueOf()
    })
    const sortList = _sortBy(newEvents, key => moment(key.dataEvento, 'DD/MM/YYYY').valueOf())
    return sortList
  }
  return events
}

function EventList ({ allEvents, eventsSelected, isReminders }) {
  const { t } = useTranslation()
  const { isSingleDateEvents, dateSelected } = eventsSelected
  const title = isSingleDateEvents ? t('calendar.dayEvents', { date: moment(dateSelected, 'DD/MM/YYYY').format('DD [de] MMMM') }) : t('calendar.nextEvents')
  const copyEvents = isSingleDateEvents ? _cloneDeep(eventsSelected.events) : _cloneDeep(allEvents)
  const events = formatEvents(copyEvents, isSingleDateEvents)
  const eventsList = _groupBy(events, 'dataEvento')
  const emptyEventList = _isEmpty(eventsList) && isSingleDateEvents

  return (
    <StyledEventList isReminders={isReminders}>
      <StyledTitlePage>{ !isReminders && title }</StyledTitlePage>
      <StyledRoot>
        {
          Object.keys(eventsList).map((key, index) => (
            <div key={index} className="container">
              <StyledEventDay isSingleDateEvents={isSingleDateEvents}>
                <span> { moment(key, 'DD/MM/YYYY').format('dddd, DD [de] MMMM') } </span>
              </StyledEventDay>
              <StyledEventsOfDay>
                {
                  _map(_get(eventsList, key, []), (item, i) => (
                    <StyledEventItem key={i}>
                      <StyledEventBullet color={item.cor}/>
                      <StyledEventDescription> {item.descricao} </StyledEventDescription>
                    </StyledEventItem>
                  ))
                }
              </StyledEventsOfDay>
            </div>
          ))
        }
        <StyledEmptyState isEmpty={emptyEventList}>
          <CalendarTodayTwoToneIcon fontSize="small" />
          <span>{t('calendar.emptyState')}</span>
        </StyledEmptyState>
      </StyledRoot>
    </StyledEventList>
  )
}

EventList.propTypes = {
  allEvents: PropTypes.array.isRequired,
  eventsSelected: PropTypes.object.isRequired,
  isReminders: PropTypes.bool
}

EventList.defaultProps = {
  isReminders: false
}

export default memo(EventList)

