import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Microregion = lazy(() => lazyRetry(() => import('containers/Microregion')))
const MicroregionRegister = lazy(() => lazyRetry(() => import('containers/Microregion/register')))

const route = [
  {
    name: i18n.t('routes.microregion.index'),
    path: '/microregion',
    main: Microregion,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_MICRORREGIAO', 'ROLE_EDITAR_CADASTRO_MICRORREGIAO']
  },
  {
    name: i18n.t('routes.microregion.register'),
    path: '/microregion/register',
    main: MicroregionRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_MICRORREGIAO']
  },
  {
    name: i18n.t('routes.microregion.edit'),
    path: '/microregion/edit/:id',
    main: MicroregionRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_MICRORREGIAO']
  }
]

export default route
