import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const AscertainmentDetailsReport = lazy(() => lazyRetry(() => import('containers/AscertainmentDetailsReport')))

const route = [
  {
    name: i18n.t('routes.ascertainmentDetailsReport.index'),
    path: '/ascertainment/details-report',
    main: AscertainmentDetailsReport,
    exact: true,
    permissions: ['ROLE_RELATORIO_COMPLIANCE_ACORDOS']
  }
]

export default route
