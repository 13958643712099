import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const ApprovalFlow = lazy(() => lazyRetry(() => import('containers/ApprovalFlow')))
const ApprovalFlowRegister = lazy(() => lazyRetry(() => import('containers/ApprovalFlow/register')))

const route = [
  {
    name: i18n.t('routes.approvalFlow.index'),
    path: '/approval-flow',
    main: ApprovalFlow,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_FLUXO_APROVACAO', 'ROLE_VISUALIZAR_CADASTRO_FLUXO_APROVACAO']
  },
  {
    name: i18n.t('routes.approvalFlow.register'),
    path: '/approval-flow/register',
    main: ApprovalFlowRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_FLUXO_APROVACAO']
  },
  {
    name: i18n.t('routes.approvalFlow.edit'),
    path: '/approval-flow/edit/:id',
    main: ApprovalFlowRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_FLUXO_APROVACAO']
  }
]

export default route
