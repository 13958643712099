import _isString from 'lodash/isString'

export const parseJwt = (token) => {
  if (!token) {
    return {
      horario: 0,
      user_name: '',
      scope: [''],
      name: '',
      restricoes: '{}',
      id: 0,
      exp: 0,
      authorities: [''],
      jti: '',
      client_id: '',
      perfil: ''
    }
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  return JSON.parse(window.atob(base64))
}

export const hasPermission = (accessToken, role) => {
  const token = parseJwt(accessToken)
  return !!(token.authorities && token.authorities.find(el => el === role))
}

export const hasAnyPermission = (accessToken, roles = []) => {
  const { authorities = [] } = parseJwt(accessToken)
  return authorities.some(item => roles.includes(item))
}

export const isTokenValid = (accessToken) => {
  if (_isString(accessToken)) {
    const { exp: expireTime } = parseJwt(accessToken)
    if (expireTime !== 0 && Date.now() < expireTime * 1000) {
      return true
    }
    return false
  }
  return false
}
