import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Profile = lazy(() => lazyRetry(() => import('containers/Profile')))
const ProfileRegister = lazy(() => lazyRetry(() => import('containers/Profile/register')))

const route = [
  {
    name: i18n.t('routes.profile.index'),
    path: '/profile',
    main: Profile,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_PERFIL', 'ROLE_EDITAR_CADASTRO_PERFIL']
  },
  {
    name: i18n.t('routes.profile.register'),
    path: '/profile/register',
    main: ProfileRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_PERFIL']
  },
  {
    name: i18n.t('routes.profile.edit'),
    path: '/profile/edit/:id',
    main: ProfileRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_PERFIL']
  }
]

export default route
