import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  header,
  #root > header ~ div,
  #root > header ~ div:last-of-type > div:first-of-type {
    display: none !important;
  }

  #root > header ~ div:last-of-type {
    display: initial !important;
  }

  * {
    font-family: 'Helvetica';
    box-sizing: border-box;
    outline: none;
  }

  body {
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #fafafa;
  }
`

export const StyledWrapper = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100vw;
    height: 20vh;
    background-color: #1089FF;
    position: fixed;
  }
`

export const StyledCard = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 48px;
  width: 722px;
  position: absolute;
  top: 8vh;
  left: 0px;
  right: 0px;
  margin: auto;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;

  legend {
    margin-bottom: 24px;
  }
`

export const StyledRadioButtons = styled.div`
  display: flex;
  margin: 0;
`

export const StyledActionButtons = styled.div`
  margin: 18px 0;
  display: flex;
  > button {
    margin-right: 16px;
  }
`

export const StyledTitle = styled.h1`
  color: #1089FF;
  text-transform: uppercase;
  font-size: 24px;
  margin: 0px;
  margin-bottom: 12px;
`

export const StyledSubTitle = styled.h2`
  color: #9e9e9e;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0px;
`

export const StyledHeader = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  position: relative;

  svg {
    position: absolute;
    top: -40px;
    right: -30px;
    font-size: 60px;
    color: #1089FF;
  }
`

export const StyledTextArea = styled.div`
  margin-top: 8px;
  margin-bottom: 20px;
  width: 100%;
`

export const StyledTextAreaLabel = styled.label`
  margin-top: 24px;
  display: block;
`
