import i18n from 'common/i18n'
import Nps from 'containers/Nps'

const route = [
  {
    name: i18n.t('routes.nps.index'),
    path: '/nps',
    main: Nps,
    exact: true
  }
]

export default route
