// import { lazy } from 'react'
// import i18n from 'common/i18n'

// const Region = lazy(() => import('containers/Region'))
// const RegionRegister = lazy(() => import('containers/Region/register'))

const route = [
  // {
  //   name: i18n.t('routes.region.index'),
  //   path: '/region',
  //   main: Region,
  //   exact: true,
  //   permissions: ['ROLE_VISUALIZAR_CADASTRO_REGIAO', 'ROLE_EDITAR_CADASTRO_REGIAO']
  // },
  // {
  //   name: i18n.t('routes.region.register'),
  //   path: '/region/register',
  //   main: RegionRegister,
  //   exact: true,
  //   permissions: ['ROLE_EDITAR_CADASTRO_REGIAO']
  // },
  // {
  //   name: i18n.t('routes.region.edit'),
  //   path: '/region/edit/:id',
  //   main: RegionRegister,
  //   exact: true,
  //   permissions: ['ROLE_EDITAR_CADASTRO_REGIAO']
  // }
]

export default route
