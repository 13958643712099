import Home from './home'
import Sandbox from './sandbox'
import Lever from './lever'
import Agreements from './agreements'
import Cashflow from './cashflow'
import Appreciation from './appreciation'
import Calc from './calc'
import CycleFolder from './cycleFolder'
import Migration from './migration'
import NewFlag from './newFlag'
import TradeAgreementsAscertainment from './tradeAgreementsAscertainment'
import Counting from './counting'
import Payments from './payments'
import Calendar from './calendar'
import AnalysisVariation from './analysisVariation'
import User from './user'
import Channel from './channel'
import Cva from './cva'
import Category from './category'
import CustomerAndSap from './customerAndSap'
import ProductIntegration from './productIntegration'
import ClientIntegration from './clientIntegration'
import EconomicGroup from './economicGroup'
import ProductsGroup from './productsGroup'
import ImpulseItem from './impulseItem'
import Brands from './brands'
import Microregion from './microregion'
import ApprovalFlow from './approvalFlow'
import InteralOrder from './internalOrder'
import Region from './region'
import InnovationProducts from './innovationProducts'
import Profile from './profile'
import BudgetType from './budgetType'
import Commodity from './commodity'
import Sku from './sku'
import Segment from './segment'
import Subregion from './subregion'
import ProductPpa from './productPpa'
import TargetExecutionProduct from './targetExecutionProduct'
import PackCategory from './packCategory'
import QuestionsGoalExecution from './questionsGoalExecution'
import CustomerPdv from './customerPdv'
import TargetExecutionCategory from './targetExecutionCategory'
import Chat from './chat'
import PpaProduct from './ppaProduct'
import Ascertainment from './ascertainment'
import PdvStoreIntegration from './pdvStoreIntegration'
import PercentageValue from './percentageValue'
import Sellin from './sellin'
import InconsistenciesDashboard from './inconsistenciesDashboard'
import ImportsExportsDashboard from './importsExportsDashboard'
import ErrorPage from './errorPage'
import NPS from './nps'
import AscertainmentACTPM from './ascertainmentACTPM'

const routes = [
  ...Home,
  ...AscertainmentACTPM,
  ...Sandbox,
  ...Lever,
  ...Agreements,
  ...Cashflow,
  ...Appreciation,
  ...Calc,
  ...CycleFolder,
  ...TradeAgreementsAscertainment,
  ...Counting,
  ...Payments,
  ...Migration,
  ...NewFlag,
  ...Calendar,
  ...AnalysisVariation,
  ...User,
  ...Channel,
  ...Cva,
  ...Category,
  ...CustomerAndSap,
  ...ProductIntegration,
  ...ClientIntegration,
  ...EconomicGroup,
  ...ProductsGroup,
  ...ImpulseItem,
  ...Brands,
  ...Microregion,
  ...ApprovalFlow,
  ...InteralOrder,
  ...Region,
  ...InnovationProducts,
  ...Profile,
  ...BudgetType,
  ...Commodity,
  ...Sku,
  ...TargetExecutionProduct,
  ...TargetExecutionCategory,
  ...Segment,
  ...Subregion,
  ...ProductPpa,
  ...PackCategory,
  ...QuestionsGoalExecution,
  ...CustomerPdv,
  ...PpaProduct,
  ...Ascertainment,
  ...PdvStoreIntegration,
  ...Chat,
  ...PercentageValue,
  ...Sellin,
  ...InconsistenciesDashboard,
  ...ImportsExportsDashboard,
  ...ErrorPage,
  ...NPS
]

export const routesPermissions = [...routes]

export default routes
