import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const PackCategory = lazy(() => lazyRetry(() => import('containers/PackCategory')))
const PackCategoryRegister = lazy(() => lazyRetry(() => import('containers/PackCategory/register')))

const route = [
  {
    name: i18n.t('routes.packCategory.index'),
    path: '/categoria-pack',
    main: PackCategory,
    exact: true,
    permissions: ['ROLE_VISUALIZAR_CADASTRO_CATEGORIA_PACK', 'ROLE_EDITAR_CADASTRO_CATEGORIA_PACK']
  },
  {
    name: i18n.t('routes.packCategory.register'),
    path: '/cadastro-categoria-pack/register',
    main: PackCategoryRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_CATEGORIA_PACK']
  },
  {
    name: i18n.t('routes.packCategory.edit'),
    path: '/cadastro-categoria-pack/edit/:id',
    main: PackCategoryRegister,
    exact: true,
    permissions: ['ROLE_EDITAR_CADASTRO_CATEGORIA_PACK']
  }
]

export default route
