import toast from 'common/utils/toast'
import _get from 'lodash/get'
import _reduce from 'lodash/reduce'
import i18n from 'common/i18n'

const isDev = process.env.NODE_ENV !== 'production'

export const showMessages = (messages, errors = 'msg_erro', success = 'msg_sucesso', toastSuccesExit = () => {}, concatMessages = false) => {
  const errorMessages = _get(messages, errors, [])
  const successMessages = _get(messages, success, [])
  if (concatMessages) {
    if (errorMessages.length) {
      const error = _reduce(errorMessages, (completeMessage, error) => `${completeMessage} ${error}`, '')
      toast.error(error)
      return null
    }
    if (successMessages.length) {
      const success = _reduce(successMessages, (completeMessage, success) => `${completeMessage} ${success}`, '')
      toast.success(success)
      return null
    }
  } else {
    if (errorMessages.length) {
      errorMessages.forEach((errorMessage) => {
        toast.error(errorMessage)
      })
      return null
    }
    if (successMessages.length) {
      successMessages.forEach((successMessage) => {
        toast.success(successMessage)
      })
      return null
    }
  }
  return null
}

const getErrorMessage = (response) => {
  const error = _get(response, 'data', {})
  if (isDev) {
    console.error(error)
  }
  return error.message || i18n.t('general.genericError')
}

export const messageServiceError = (response) => {
  toast.error(getErrorMessage(response))
}

export const messageServiceErrorWithOptions = options => (response) => {
  toast.error(getErrorMessage(response), options)
}
