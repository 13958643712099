import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Calc = lazy(() => lazyRetry(() => import('containers/Calc')))
const CalcRegister = lazy(() => lazyRetry(() => import('containers/Calc/CalcRegister')))

const route = [
  {
    name: i18n.t('routes.calc.index'),
    path: '/calc',
    main: Calc,
    exact: true,
    permissions: [
      'ROLE_EDITAR_CADASTRO_SELL_IN',
      'ROLE_EDITAR_CADASTRO_SELL_OUT',
      'ROLE_EDITAR_CALCULO_ACORDOS',
      'ROLE_EDITAR_EXECUCAO_METAS',
      'ROLE_VISUALIZAR_CADASTRO_SELL_IN',
      'ROLE_VISUALIZAR_CADASTRO_SELL_OUT',
      'ROLE_VISUALIZAR_CALCULO_ACORDOS',
      'ROLE_VISUALIZAR_EXECUCAO_METAS'
    ]
  },
  {
    name: i18n.t('routes.calc.register'),
    path: '/calc/register',
    main: CalcRegister,
    exact: true,
    permissions: [
      'ROLE_EDITAR_CADASTRO_SELL_IN',
      'ROLE_EDITAR_CADASTRO_SELL_OUT',
      'ROLE_EDITAR_CALCULO_ACORDOS',
      'ROLE_EDITAR_EXECUCAO_METAS'
    ]
  }
]

export default route
