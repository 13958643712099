const fileDownload = ({ file, name, extension, type }) => {
  const url = URL.createObjectURL(new Blob([file], { type }))
  const link = document.createElement('a')

  link.href = url
  link.setAttribute('download', `${name}.${extension}`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

const fileDownloadWithBlob = ({ file, name, extension }) => {
  const url = URL.createObjectURL(file)
  const link = document.createElement('a')

  link.href = url
  link.setAttribute('download', `${name}.${extension}`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

const base64toBlob = (base64Data, contentType) => {
  const sliceSize = 1024
  const byteCharacters = window.atob(base64Data)
  const bytesLength = byteCharacters.length
  const slicesCount = Math.ceil(bytesLength / sliceSize)
  const byteArrays = new Array(slicesCount)

  for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex += 1) {
    const begin = sliceIndex * sliceSize
    const end = Math.min(begin + sliceSize, bytesLength)

    const bytes = new Array(end - begin)
    for (let i = 0, offset = begin; offset < end; i += 1, offset += 1) {
      bytes[i] = byteCharacters[offset].charCodeAt(0)
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes)
  }
  return new window.Blob(byteArrays, { type: contentType || '' })
}

export {
  fileDownload,
  fileDownloadWithBlob,
  base64toBlob
}
