import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Cashflow = lazy(() => lazyRetry(() => import('containers/Cashflow')))
const AddCounting = lazy(() => lazyRetry(() => import('containers/Cashflow/newCounting')))

const route = [
  {
    name: i18n.t('routes.cashflow.index'),
    path: '/cashflow',
    main: Cashflow,
    exact: true,
    permissions: [
      'ROLE_VISUALIZAR_APURACOES_AVULSO'
    ]
  },
  {
    name: i18n.t('routes.cashflow.newCounting'),
    path: '/cashflow/newCounting',
    main: AddCounting,
    exact: true,
    permissions: ['ROLE_EDITAR_APURACAO_AVULSO']
  }
]

export default route
